import React, { Component } from 'react';
import debounce from 'lodash.debounce';
import AppStore from '@/app-store';
import AppBrick from '@/component/AppBrick';
import CacheHelper from '@/helper/cache-helper';
import LinkAppBrick from '@/component/LinkAppBrick';
import UserInterfaceHelper from '@/helper/user-interface-helper';
import analyticsHelper from '@/helper/analytics-helper';
import LoadingIcon from '@/component/LoadingIcon';

import { SPECIAL_CATE_CODE, APPBRICK_LOCATION } from '@/constant';

import 'scss/AppListView.scss';

class AppListView extends Component {
  constructor(props) {
    super(props);
    const { categoryCode } = props;
    this.listItemRefs = {};
    this.appListRef = React.createRef();
    // This debounced method is to handle user switching category rapidly
    // The API calls will be fired if the user stays at the cate for more than 200 ms
    this.fetchApps = debounce(this.fetchApps, 200);
    this.state = {
      apps: [],
      currentBrickIndex: UserInterfaceHelper.cateHistory[categoryCode] || 0,
      isFetching: true,
      isChangingCategory: false,
      isLastPage: false,
    };
  }

  componentDidMount() {
    const { categoryCode } = this.props;

    window.addEventListener('appstore:change', this.handleStoreChange);

    if (this.shouldFetchApps(categoryCode)) {
      this.fetchApps(categoryCode);
    } else {
      this.getApps();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      currentBrickIndex: prevBrickIndex,
      apps: prevApps,
      isChangingCategory: wasChangingCategory,
    } = prevState;
    const { currentBrickIndex, apps, isChangingCategory } = this.state;
    const { categoryCode: prevCateCode } = prevProps;
    const { categoryCode } = this.props;

    /**
     * handle focus for: after nav by user
     */
    if (prevBrickIndex !== currentBrickIndex) {
      this.handleFocus();
    }

    /**
     * Handle focus for case that there was app in the cate
     * but fetch app later.
     * Or focus when category changing has started (so that the focus can change
     * from dismissed brick to appListRef) or ended (from appListRef to valid
     * brick).
     */
    if (
      prevCateCode === categoryCode &&
      prevBrickIndex === currentBrickIndex &&
      (prevApps[prevBrickIndex] !== apps[currentBrickIndex] ||
        wasChangingCategory !== isChangingCategory)
    ) {
      this.handleFocus();
    }

    if (prevCateCode !== categoryCode) {
      /**
       * Due to user may switch category rapidly, if the app list of the current
       * category hasn't been loaded, we should only record the brick index
       * as a default value 0, not the previous brick index.
       */
      if (isChangingCategory) {
        UserInterfaceHelper.cateHistory[prevCateCode] = 0;
      } else {
        UserInterfaceHelper.cateHistory[prevCateCode] = prevBrickIndex;
      }

      this.setState({
        apps: [],
        isChangingCategory: true,
        isFetching: true,
      });

      if (this.shouldFetchApps(categoryCode)) {
        this.fetchApps(categoryCode);
      } else {
        this.getApps();
      }
    }
  }

  componentWillUnmount() {
    const { currentBrickIndex } = this.state;
    const { categoryCode } = this.props;
    UserInterfaceHelper.cateHistory[categoryCode] = currentBrickIndex;
    window.removeEventListener('appstore:change', this.handleStoreChange);
  }

  getCurrentBrick(categoryCode) {
    return UserInterfaceHelper.cateHistory[categoryCode] || 0;
  }

  getApps(resetBrickIndex = true) {
    const { categoryCode } = this.props;
    const { apps = [], isLastPage = false } = AppStore.getAppsByCate(
      categoryCode
    );

    this.setListItemRefs(apps);
    this.setState({
      apps,
      ...(resetBrickIndex && {
        currentBrickIndex: this.getCurrentBrick(categoryCode),
      }),
      isFetching: false,
      isChangingCategory: false,
      isLastPage,
    });
  }

  setListItemRefs(apps) {
    this.listItemRefs = apps.reduce((acc, app) => {
      acc[app.id] = React.createRef();
      return acc;
    }, {});
  }

  handleStoreChange = () => {
    // Prevent unnecessary setState cause component re-render.
    if (this.state.isFetching) return;

    this.getApps(false);
  };

  handleKeydown = e => {
    switch (e.key) {
      case 'ArrowUp':
        this._nav(-1);
        e.stopPropagation();
        break;
      case 'ArrowDown':
        this._nav(1);
        e.stopPropagation();
        break;
      default:
        // do nothing, pass to parent.
        break;
    }
  };

  handleFocus = () => {
    const { currentBrickIndex, apps } = this.state;

    if (UserInterfaceHelper.popupVisible === true) {
      return;
    }
    console.error('[store focus debug handleFocus]');
    if (apps.length === 0) {
      // still have to focus on something or the keydown will freeze
      console.error('[store focus debug no Apps]');
      this.appListRef.current.focus();
    } else {
      const app = apps[currentBrickIndex];
      const { id: appId } = app;
      const brick = this.listItemRefs[appId].current;
      const { categoryCode } = this.props;
      console.error(`[store focus debug try to focus on ]${currentBrickIndex}`);
      if (brick) {
        console.error(`[store focus debug handleFocus]: ${brick}`);
        brick.handleFocus();
        analyticsHelper.saveViewedApp({
          appOrder: currentBrickIndex,
          appId,
          categoryCode,
        });
        analyticsHelper.setIsYmalBrowsing(false);
      } else {
        console.error(`[store focus debug can't find brick ]`);
        this.appListRef.current.focus();
      }
    }
  };

  fetchApps = categoryCode => {
    AppStore.fetchAppListByCate(categoryCode).then(({ apps, isLastPage }) => {
      // Dont re-render app-list if category has been changed before
      // fetching complete.
      if (this.props.categoryCode !== categoryCode) {
        return;
      }

      const isEmptyList = apps.length === 0 && isLastPage;
      if (isEmptyList) {
        CacheHelper.removeInvalidCategory(categoryCode);
      }
      const currentBrick = this.getCurrentBrick(categoryCode);

      this.setListItemRefs(apps);
      this.setState({
        apps,
        currentBrickIndex: currentBrick,
        isFetching: false,
        isChangingCategory: false,
        isLastPage,
      });
    });
  };

  shouldFetchApps(categoryCode) {
    return (
      categoryCode !== SPECIAL_CATE_CODE.RECOMMENDED &&
      !AppStore.isCarrierCate(categoryCode) &&
      !AppStore.appsByRanking.get(categoryCode)
    );
  }

  _nav(move) {
    const { currentBrickIndex, apps, isLastPage, isFetching } = this.state;
    const numberOfApps = apps.length;
    const nextIndex = currentBrickIndex + move;

    // Try fetching more apps if there are only 5 more apps left in the list
    if (
      numberOfApps - nextIndex <= 5 &&
      move === 1 &&
      !isFetching &&
      !isLastPage
    ) {
      const { categoryCode } = this.props;
      UserInterfaceHelper.cateHistory[categoryCode] = nextIndex;
      this.setState({
        isFetching: true,
      });
      this.fetchApps(categoryCode);
    }

    if (nextIndex >= 0 && nextIndex < numberOfApps) {
      this.setState({ currentBrickIndex: nextIndex });
    }
  }

  render() {
    const { apps, isChangingCategory, isLastPage } = this.state;

    return (
      <div
        className="AppListView"
        tabIndex="1"
        onKeyDown={this.handleKeydown}
        onFocus={this.handleFocus}
        ref={this.appListRef}
      >
        {(() => {
          if (apps.length === 0 && isLastPage && !isChangingCategory) {
            return <EmptyTips />;
          } else if (apps.length > 0 && !isChangingCategory) {
            return (
              <div className="container with-padding-bot">
                {apps.map(
                  (app, index) =>
                    app.info.type === 'link' ? (
                      <LinkAppBrick
                        key={app.id}
                        ref={this.listItemRefs[app.id]}
                        app={app}
                      />
                    ) : (
                      <AppBrick
                        key={app.id}
                        ref={this.listItemRefs[app.id]}
                        data-id={app.id}
                        appOrder={index}
                        app={app.brickInfo}
                        mozAPP={app.mozAPP}
                        location={APPBRICK_LOCATION.APPLIST}
                      />
                    )
                )}
              </div>
            );
          } else {
            return <LoadingIcon />;
          }
        })()}
      </div>
    );
  }
}

const EmptyTips = () => {
  return (
    <div className="empty-tips">
      <div className="image" />
      <div className="tips" data-l10n-id="empty-applist-warning" />
    </div>
  );
};

export default AppListView;
