import 'scss/AppsView.scss';

import React, { Component } from 'react';
import AppListView from './AppListView';
import AppUpdateListView from './AppUpdateListView';
import CategoryTabs from './CategoryTabs';

import AppStore from '@/app-store';
import { scrollToHorizontalCenter } from '@/helper/user-interface-helper';
import analyticsHelper from '@/helper/analytics-helper';
import routeHelper from '@/helper/route-helper';

const _ = navigator.mozL10n.get;

class AppsView extends Component {
  constructor(props) {
    super(props);
    this.carrierCategory = AppStore.categories.carrier;
    this.otherCategories = [
      ...AppStore.categories.recommended,
      ...AppStore.categories.static,
    ];
    this.appsViewRef = React.createRef();
    this.appListViewRef = React.createRef();
    this.tabRefs = {};
    const categories = this.getCategories();
    this.state = {
      // categories will change if there is new updates or updates are done
      categories,
      currentCateCode: categories[0].code,
    };
  }

  componentDidMount() {
    window.addEventListener('appstore:change', this.handleStoreChange);
    const { categoryCode } = this.props;
    this.updateCurrentCategory(categoryCode);
  }

  componentDidUpdate(prevProps, prevState) {
    const { categories: currentCategories, currentCateCode } = this.state;
    const { categories: prevCategories } = prevState;

    if (prevCategories.length !== currentCategories.length) {
      const isCurrentCateExist = currentCategories.find(category => {
        return category.code === this.state.currentCateCode;
      });
      if (!isCurrentCateExist) {
        /**
         * Fallback to first cate if current cate disappeared, it could only be
         * update tab at this stage
         */
        this.setState(
          {
            currentCateCode: currentCategories[0].code,
          },
          // when updating is done, switch to the first tab with scrolling
          this.activeTab
        );
      } else {
        // when there is new update, expand the tab but stay on the current tab with scrolling if necessary
        this.activeTab(true);
      }
    }

    if (currentCateCode !== prevState.currentCateCode) {
      routeHelper.updateCurrentParams({
        key: 'categoryCode',
        value: currentCateCode,
      });
    }

    if (
      prevProps.categoryCode !== this.props.categoryCode ||
      routeHelper.forceUpdateCurrentCategory
    ) {
      routeHelper.forceUpdateCurrentCategory = false;
      this.updateCurrentCategory(this.props.categoryCode);
    }

    if (
      prevProps.visibilityState === 'hidden' &&
      this.props.visibilityState === 'visible'
    ) {
      this.activeTab(false);
      console.error(`[store focus debug force call focus]`);
      this.handleFocus();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('appstore:change', this.handleStoreChange);
  }

  get currentCateIndex() {
    const { currentCateCode, categories } = this.state;
    // return 0 if category code is not found
    return Math.max(
      categories.findIndex(category => {
        return category.code === currentCateCode;
      }),
      0
    );
  }

  updateCurrentCategory(code) {
    const parsedCode = parseInt(code, 10) || code;
    const { categories } = this.state;
    const cateIndex = categories.findIndex(cate => cate.code === parsedCode);
    if (cateIndex !== -1) {
      this.setState(
        {
          currentCateCode: parsedCode,
        },
        () => {
          // should switch to the last visited tab immediately
          this.activeTab(false);
        }
      );
    } else {
      // should focus on the default tab (which is the first tab) immediately
      this.activeTab(false);
    }
  }

  getCategories() {
    // Order of categories: carrier > update > recommended > static
    const updateCate = {
      displayName: _('update'),
      code: 'update',
    };
    const categories = AppStore.hasUpdatableApps
      ? [...this.carrierCategory, updateCate, ...this.otherCategories]
      : [...this.carrierCategory, ...this.otherCategories];
    this.tabRefs = categories.reduce(
      (acc, cate) => ({
        ...acc,
        [cate.code]: React.createRef(),
      }),
      // This initial object is for the parentElement of the tabs
      { box: React.createRef() }
    );
    return categories;
  }

  handleStoreChange = () => {
    this.setState({
      categories: this.getCategories(),
    });
  };

  handleFocus = () => {
    console.error('[store focus debug _focus]');
    this._focus();
  };

  handleKeydown = e => {
    switch (e.key) {
      case 'ArrowRight':
        e.preventDefault();
        this._nav(1);
        break;
      case 'ArrowLeft':
        e.preventDefault();
        this._nav(-1);
        break;
      case 'ArrowDown':
      case 'ArrowUp':
        this._blur();
        break;
      case 'BrowserBack':
      case 'Backspace':
      case 'Escape':
      case 'Enter':
      case 'SoftRight':
      case 'SoftLeft':
        // pass to parent.
        break;
      case 'Accept':
      default:
        // do nothing.
        e.stopPropagation();
        break;
    }
  };

  _nav(move) {
    const { categories } = this.state;
    const reverse = document.dir === 'rtl' ? -1 : 1;
    const newCateIndex = this.currentCateIndex + move * reverse;
    if (
      this.currentCateIndex !== newCateIndex &&
      newCateIndex >= 0 &&
      newCateIndex < categories.length
    ) {
      this.setState(
        { currentCateCode: categories[newCateIndex].code },
        // navigate between tabs should apply smooth scrolling
        this.activeTab
      );
    }
  }

  _focus() {
    // FIXME: flatten the component trees for this hack.
    const focusTA = this.appListViewRef.current.querySelector('.AppListView');
    console.error(`[store focus debug focusTA]: ${focusTA}`);
    this.appListViewRef.current.querySelector('.AppListView').focus();
  }

  _blur() {
    this.appsViewRef.current.blur();
  }

  // Note: apply scrolling effect only when switching tabs; no scrolling when returning from other panels
  activeTab(smoothScrolling = true) {
    const { currentCateCode } = this.state;
    const currentTab = this.tabRefs[currentCateCode].current;
    const parent = this.tabRefs.box.current;
    this.saveViewedTab();
    /**
     * Only try to scroll when document is visible, otherwise offsetWidth of the
     * container would be 0.
     */
    if (document.visibilityState === 'visible') {
      scrollToHorizontalCenter(currentTab, parent, smoothScrolling);
    }
  }

  saveViewedTab() {
    const { currentCateCode, categories } = this.state;
    analyticsHelper.saveViewedTab({
      cateCode: currentCateCode,
      firstTabCode: categories[0].code,
    });
  }

  render() {
    const { categories, currentCateCode } = this.state;
    return (
      <div
        className="AppsView"
        tabIndex="1"
        ref={this.appsViewRef}
        onKeyDown={this.handleKeydown}
        onFocus={this.handleFocus}
      >
        <CategoryTabs
          categories={categories}
          currentCateIndex={this.currentCateIndex}
          ref={this.tabRefs}
        />
        <div className="ListviewBox" ref={this.appListViewRef}>
          {currentCateCode === 'update' ? (
            <AppUpdateListView
              order={this.currentCateIndex}
              categoryCode={currentCateCode}
            />
          ) : (
            <AppListView categoryCode={currentCateCode} />
          )}
        </div>
      </div>
    );
  }
}

export default AppsView;
