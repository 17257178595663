export const normalizedURL = url => {
  return new URL(url).href;
};

export const isValidURL = URL => {
  const regexQuery =
    '^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$';
  const url = new RegExp(regexQuery, 'i');
  return url.test(URL);
};

export const localeCode = info => {
  const langCode = navigator.mozL10n.language.code;
  const defaultLocale = info.default_locale;
  const langCodesInLocales = Object.keys(info.locales);
  if (langCodesInLocales.includes(langCode)) {
    return langCode;
  }
  return langCodesInLocales.includes(defaultLocale) ? defaultLocale : 'en-US';
};

export const trimBgs = bgs => {
  if (bgs && Object.keys(bgs).length > 0) {
    return bgs[Object.keys(bgs)[0]];
  }
  return null;
};

export const getFallbackIcon = (icons, sizes) => {
  const fallbackSizes = sizes.filter(icon => {
    return icons[icon];
  });

  return icons[fallbackSizes[0]] || null;
};

export const trimIcons = icons => {
  if (!icons) {
    return null;
  }
  const defaultSize = '56';
  const sizes = Object.keys(icons);
  if (sizes.length === 0) {
    return null;
  }

  // icons[`size_${defaultSize}`] for graphQL key
  return (
    icons[defaultSize] ||
    icons[`size_${defaultSize}`] ||
    getFallbackIcon(icons, sizes)
  );
};

// Used as fallback for getRemoteLocales() or handle mozApp locale
export const trimLocales = (locales, langCode) => {
  if (!locales || !langCode) {
    return {};
  }
  /*
   * XXX: Since there is a limitation from graphql (not allowed having "-" in
   * attribute), to prevent the page cannot get the locale properly, let's
   * check lang (e.g. en-US) first. If locale is not found, then check lang
   * that replaces "-" with "_" (e.g. en_US).
   */
  return (
    locales[langCode] ||
    locales[langCode.replace(/-/g, '_')] ||
    // default is a special key in graphQL response
    locales.default ||
    Object.values(locales)[0] ||
    {}
  );
};

// Source of remote apps: SD applist api (new format), search api (transform to new format in SearchPanel), and graphQL
export const getRemoteLocales = remoteInfo => {
  const { locales, default_locale: defaultLocale } = remoteInfo;
  const langCode = navigator.mozL10n.language.code || defaultLocale || 'en-US';
  // XXX: Handle some SD applist api FAILED to handle, fallback to the old way OR the graphQL endpoint
  if (locales) {
    return trimLocales(locales, langCode);
  }
  const { display: name, description, subtitle } = remoteInfo;
  return { name, description, subtitle };
};

export const getSize = byte => {
  if (typeof byte !== 'number' || Number.isNaN(byte)) return null;
  const thousandKB = 1024 * 1024;
  return byte < thousandKB
    ? `${(byte / 1024).toFixed(1)}KB`
    : `${(byte / 1024 / 1024).toFixed(1)}MB`;
};
