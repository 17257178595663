import { postThroughClient } from '@/helper/http-request-helper';
import { deviceUtils } from '../device-utils';
import { isNextOS } from '@/utils';

const mapDownloadErrorsToLoggerStatus = {
  NETWORK_ERROR: 0,
  DOWNLOAD_ERROR: 101,
  CHECK_DEPENDENCIES_ERROR: 108,
  INSUFFICIENT_STORAGE: 104,
};

export const bookmarkEvent = {
  OPEN: 'store_bm_open',
  PIN: 'store_bm_pin',
};

class LoggerHelper {
  constructor() {
    this._apiUri = null;
    this._apiName = '/events';
    this._url = null;
    this._isStreamEnabled = false;
    this._isNextOS = false;
  }

  init() {
    this._apiUri = deviceUtils.settings.get('apps.serviceCenterUrl');
    this._url = `${this._apiUri}${this._apiName}`;
    this._isStreamEnabled = !this._apiUri.includes('jiophone.net');
    this._isNextOS = isNextOS();
  }

  postData(data) {
    if (!this._isStreamEnabled || this._isNextOS) {
      return;
    }

    postThroughClient(this._url, {
      useDeviceInfo: true,
      params: data,
    }).catch(error => {
      console.error(`logger: ${error.statusText}`);
    });
  }

  postAppInstallData(appsInfo, error = null) {
    const logsInfo = {
      ...appsInfo,
      event_type: 'store_app_install',
      status_code: error === null ? 1 : this._getInstallErrorStatus(error),
    };
    this.postData(logsInfo);
  }

  postAppViewData(activeApp, appOrder = null, adId = null, categoryCode = '') {
    // Values in logsInfo should be string
    const logsInfo = {
      event_type: 'store_app_view',
      app_id: activeApp.id,
      app_version: activeApp.info.version,
      app_name: activeApp.info.localized.name,
      tab_code: categoryCode && categoryCode.toString(),
    };
    if (appOrder !== null) {
      logsInfo.app_order = parseInt(appOrder, 10);
    }
    if (adId !== null) {
      logsInfo.ad_id = adId;
    }
    this.postData(logsInfo);
  }

  postBookmarkData(eventType, id, info) {
    const { localized, version } = info;
    const data = {
      event_type: eventType,
      app_version: version,
      app_id: id,
      app_name: localized.name,
    };
    this.postData(data);
  }

  _getInstallErrorStatus(message) {
    const status = mapDownloadErrorsToLoggerStatus[message];
    return status === undefined
      ? mapDownloadErrorsToLoggerStatus.DOWNLOAD_ERROR
      : status;
  }
}
export const loggerHelper = new LoggerHelper();
