import 'scss/component/BulletTitle.scss';

import cx from 'classnames';
import React from 'react';

const BulletTitle = props => {
  const classes = cx('bullet-title', {
    [props.customClass]: props.customClass,
  });
  return (
    <div className={classes}>
      <div className="title" data-l10n-id={props.titleL10nId} />
      {props.children}
    </div>
  );
};

export default BulletTitle;
