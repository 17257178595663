import React, { Component } from 'react';
import ReactDialog from 'react-dialog';
import cx from 'classnames';
import { MessageSender } from 'web-message-helper';
import { CheckIfStorageEnoughCommand } from 'kaistore-post-messenger/src/commands';
import { validStorageSections } from 'kaistore-post-messenger/lib/constants';

import AppStore from '@/app-store';
import { PATH } from '@/constant';
import { clamp, registerSoftKey } from '@/utils';
import UserInterfaceHelper from '@/helper/user-interface-helper';
import analyticsHelper from '@/helper/analytics-helper';
import routeHelper from '@/helper/route-helper';

import Separator from '@/component/Separator';
import UpdateBrick from '@/component/updateTab/UpdateBrick';
import UpdateButton from '@/component/updateTab/UpdateButton';

const FOCUSABLE_SELECTOR = '.focusable';

class AppUpdateListView extends Component {
  constructor(props) {
    super(props);

    const updatableApps = this.getUpdatableApps();

    this.state = {
      updatableSystemApps: updatableApps.system,
      updatableRemoteApps: updatableApps.remote,
      isUpdatingAll: AppStore.isUpdateAllInProcess,
      dialog: false,
      dialogOptions: {},
      currentNavIndex: 0,
    };
    this.element = null;
    this.dialogRef = React.createRef();
    this.updateButtonRef = {
      system: React.createRef(),
      remote: React.createRef(),
    };
  }

  componentDidMount() {
    const { categoryCode } = this.props;
    const appOrder = UserInterfaceHelper.cateHistory[categoryCode];
    const appOrderOverflow = appOrder >= this.focuables.length;
    if (appOrder) {
      const index = clamp(appOrder, 0, this.focuables.length - 1);
      this.setState({
        currentNavIndex: index,
      });
    }
    window.addEventListener('appstore:change', this.handleStoreChange);
    this.focus();
    this.updateSoftKeys();
  }

  componentWillUnmount() {
    this.element = null;
    const { categoryCode } = this.props;
    UserInterfaceHelper.cateHistory[categoryCode] = this.state.currentNavIndex;
    window.removeEventListener('appstore:change', this.handleStoreChange);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.dialog !== this.state.dialog) {
      if (this.state.dialog) {
        this.onDialogInShowState();
      } else {
        this.onDialogInHideState();
      }
    }
    if (prevState.currentNavIndex !== this.state.currentNavIndex) {
      this.focus();
    }
    const updatableRemoteAppsChanged =
      prevState.updatableRemoteApps.length !==
        this.state.updatableRemoteApps.length ||
      prevState.updatableSystemApps.length !==
        this.state.updatableSystemApps.length;
    const navIndexOverflow =
      this.state.currentNavIndex >= this.focuables.length;
    if (updatableRemoteAppsChanged && navIndexOverflow) {
      this.setState({ currentNavIndex: this.focuables.length - 1 });
    }
  }

  updateSoftKeys() {
    const { isUpdatingAll } = this.state;
    const isStoppable = {
      system: Boolean(AppStore.nextUpdatePendingSystemApp),
      remote: Boolean(AppStore.nextUpdatePendingRemoteApp),
    };
    Object.keys(isStoppable).forEach(key => {
      if (!this.updateButtonRef[key].current) {
        return;
      }
      registerSoftKey(
        {
          center: isStoppable[key]
            ? 'stop'
            : isUpdatingAll[key]
              ? ''
              : 'select',
        },
        this.updateButtonRef[key].current
      );
    });
  }

  focus() {
    if (!this.element) {
      return;
    }
    const index = this.state.currentNavIndex;
    const element =
      index >= this.focuables.length
        ? this.focuables[this.focuables.length - 1]
        : this.focuables[index];
    if (!element) {
      return;
    }
    element.focus();
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }

  handleStoreChange = () => {
    const updatableApps = this.getUpdatableApps();
    const isUpdatingAll = {};
    this.updatableMeta.forEach(item => {
      const { storeKey } = item;
      /**
       * Set to false if one of the conditions is met:
       * there's no app pending to download or downloading, or
       * user has already press stop (state.isUpdatingAll[type]: false)
       */
      const lastUpdatableAppDownloaded =
        !AppStore.isUpdateAllInProcess[storeKey] &&
        this.state.isUpdatingAll[storeKey];
      isUpdatingAll[storeKey] = lastUpdatableAppDownloaded
        ? false
        : this.state.isUpdatingAll[storeKey];
    });
    this.setState(
      {
        updatableSystemApps: updatableApps.system,
        updatableRemoteApps: updatableApps.remote,
        isUpdatingAll,
      },
      () => {
        this.updateSoftKeys();
      }
    );
  };

  getUpdatableApps() {
    const result = {};
    this.updatableMeta.forEach(item => {
      const { storeKey } = item;
      result[storeKey] = AppStore.updatableApps[storeKey];
    });
    return result;
  }

  updateAll(type) {
    MessageSender.send(
      new CheckIfStorageEnoughCommand({
        detail: { section: validStorageSections.UPDATE },
      }),
      isEnough => {
        if (!isEnough) {
          this.setState({
            isUpdatingAll: {
              ...this.state.isUpdatingAll,
              [type]: false,
            },
          });
          return;
        }
        if (type === 'system' && AppStore.updateAllRebootNeeded) {
          this.showRebootNeededDialog();
        } else {
          AppStore.updateAll(type, true);
        }
      }
    );
  }

  onUpdateBtnPressed(type, enable) {
    this.setState(
      {
        isUpdatingAll: {
          ...this.state.isUpdatingAll,
          [type]: enable,
        },
      },
      () => {
        if (enable) {
          this.updateAll(type);
        } else {
          AppStore.updateAll(type, enable);
        }
      }
    );
  }

  onBrickPress = app => {
    const URL = app.core
      ? PATH.CORE_PAGE.URL({ manifest: app.manifestURL })
      : PATH.PAGE.URL({ manifest: app.manifestURL });
    routeHelper.route(URL);
  };

  onBrickFocus = app => {
    const index = this.state.currentNavIndex;
    analyticsHelper.saveViewedApp({
      appOrder: index,
      appId: app.id,
      categoryCode: 'update',
    });
    analyticsHelper.setIsYmalBrowsing(false);
  };

  showRebootNeededDialog() {
    const dialogOptions = {
      header: 'update-all',
      type: 'confirm',
      ok: 'update',
      content: 'update-all-and-restart-dialog',
      onOk: () => {
        AppStore.updateAll('system', true);
      },
    };

    this.setState({
      dialog: true,
      dialogOptions,
    });
  }

  hideDialog = () => {
    this.setState({
      dialog: false,
    });
  };

  onDialogInShowState() {
    const lastActive = document.activeElement;
    this.dialogRef.current.show();

    this.dialogRef.current.on('closed', () => {
      if (this.state.dialog) {
        // close by endKey
        this.hideDialog();
      }
      lastActive.focus();
    });
  }

  onDialogInHideState() {
    this.dialogRef.current.hide();
    this.focus();
  }

  handleKeydown = e => {
    switch (e.key) {
      case 'ArrowUp':
        this._nav(-1, e);
        break;
      case 'ArrowDown':
        this._nav(1, e);
        break;
      case 'ArrowRight':
      case 'ArrowLeft':
      case 'BrowserBack':
      case 'Accept':
      case 'Enter':
      default:
        // do nothing, pass to parent.
        break;
    }
  };

  handleFocus = () => {
    this.focus();
  };

  _nav(move, e) {
    const navNumber = this.focuables.length;
    let nextIndex = parseInt(this.state.currentNavIndex, 10) + move;

    if (nextIndex < 0) {
      this.setState({ currentNavIndex: 0 });
    } else if (nextIndex < navNumber) {
      nextIndex %= navNumber;
      this.setState({ currentNavIndex: nextIndex });
    }
    e.stopPropagation();
  }

  get updatableMeta() {
    return [
      {
        stateKey: 'updatableSystemApps',
        l10nId: 'system-software-update',
        storeKey: 'system',
      },
      {
        stateKey: 'updatableRemoteApps',
        l10nId: 'app-update',
        storeKey: 'remote',
      },
    ];
  }

  get focuables() {
    return this.element.querySelectorAll(FOCUSABLE_SELECTOR);
  }

  render() {
    const { isUpdatingAll, dialog: showDialog } = this.state;
    const updateDialogClasses = cx({
      hidden: !showDialog,
    });
    const validUpdatableMeta = this.updatableMeta.filter(
      item => this.state[item.stateKey].length > 0
    );
    return (
      <div
        className="AppListView"
        tabIndex="1"
        ref={element => {
          this.element = element;
        }}
        onFocus={this.handleFocus}
        onKeyDown={this.handleKeydown}
      >
        {validUpdatableMeta.map((item, index) => {
          const updatableApps = this.state[item.stateKey];
          const isLastMeta = index === validUpdatableMeta.length - 1;
          return (
            <div
              className={`section ${isLastMeta ? 'with-padding-bot' : ''}`}
              key={item.storeKey}
            >
              <Separator l10nId={item.l10nId} number={updatableApps.length} />
              <UpdateButton
                l10nId={
                  isUpdatingAll[item.storeKey]
                    ? 'update-page-desc-updating'
                    : 'update-all'
                }
                onPress={() => {
                  this.onUpdateBtnPressed(
                    item.storeKey,
                    !isUpdatingAll[item.storeKey]
                  );
                }}
                ref={this.updateButtonRef[item.storeKey]}
              />
              <AppList
                updatableApps={updatableApps}
                onPress={this.onBrickPress}
                onBrickFocus={this.onBrickFocus}
              />
            </div>
          );
        })}
        <div id="update-dialog-root" className={updateDialogClasses}>
          <ReactDialog ref={this.dialogRef} {...this.state.dialogOptions} />
        </div>
      </div>
    );
  }
}

const AppList = props => {
  const { updatableApps, onPress, onBrickFocus } = props;
  return updatableApps.map((app, index) => (
    <UpdateBrick
      localized={app.info.localized}
      size={app.info.size}
      icon={app.info.icon}
      isDownloading={app.state.downloading}
      downloadProgress={app.mozAPP.progress}
      isPending={app.state.updatePending}
      key={`update-brick-${index}`}
      onPress={() => {
        onPress && onPress(app);
      }}
      onBrickFocus={() => {
        onBrickFocus && onBrickFocus(app);
      }}
    />
  ));
};

export default AppUpdateListView;
