import React, { PureComponent } from 'react';

import Marquee from '@/component/Marquee';
import { isOverflown } from '@/helper/user-interface-helper';

export default class OptionMenuLibText extends PureComponent {
  constructor(props) {
    super(props);
    this.container = React.createRef();
    this.state = {
      isTextOverflown: false,
    };
  }

  componentDidMount() {
    const isTextOverflown = isOverflown(this.container.current);

    if (!this.state.isTextOverflown && isTextOverflown) {
      this.setState({
        isTextOverflown,
      });
    }
  }

  render() {
    const { isTextOverflown } = this.state;
    const { text, isActive } = this.props;

    return (
      <p className="content_text" ref={this.container}>
        {this.container.current && isActive && isTextOverflown ? (
          <Marquee>
            <p>{text}</p>
          </Marquee>
        ) : (
          <span>{text}</span>
        )}
      </p>
    );
  }
}
