import { route } from 'preact-router';
import { PATH } from '@/constant';

class RouteHelper {
  constructor() {
    this.currentPath = null;
    this.entryURL = null;
    this.forceUpdateCurrentCategory = false;
  }

  route(path) {
    // TODO: handle case for 'dialog' in querystring, copy the search param to the new route
    route(path);
    this.currentPath = path;
  }

  get isOnLoadingPage() {
    return this.currentPath === PATH.LOADING.URL();
  }

  updateCurrentParams({ key, value }) {
    const URLObject = new URL(window.location.href);
    URLObject.searchParams.set(key, value);
    window.history.replaceState({}, '', URLObject.href);
  }
}

export default new RouteHelper();
