import 'scss/component/Separator.scss';

import React from 'react';

import BulletTitle from '@/component/BulletTitle';

export const getIndicatorStr = originalNumber => {
  if (
    !originalNumber ||
    typeof originalNumber !== 'number' ||
    originalNumber <= 0
  ) {
    return null;
  }

  return originalNumber > 99 ? '99+' : Math.floor(originalNumber).toString();
};

export const Indicator = ({ number }) => {
  const indicatorNumber = getIndicatorStr(number);
  return indicatorNumber ? (
    <div className="indicator">
      <span>{indicatorNumber}</span>
    </div>
  ) : null;
};

const Separator = React.memo(function Separator(props) {
  const { l10nId, number } = props;

  return (
    <BulletTitle titleL10nId={l10nId} customClass="separator">
      <Indicator number={number} />
    </BulletTitle>
  );
});

export default Separator;
