import React, { Component } from 'react';
import SoftKeyStore from 'soft-key-store';

import { registerSoftKey } from '@/utils';

import Options from './Options';
import 'scss/component/OptionMenuLib.scss';

/**
 * TODO:
 * This component originated in https://git.kaiostech.com/shared/react-option-menu
 * We should refactor this component in the future.
 */
export default class OptionMenuLib extends Component {
  constructor(props) {
    super(props);
    this.name = 'OptionMenuLib';
    this.contentRef = React.createRef();
    this.state = {
      header: 'options',
      options: [],
      onCancel: () => {},
      hasCancel: false,
      enableGoBack: true,
      history: [],
      currentFocusIndex: 0,
      optionsRefs: [],
      showScrollUpIndicator: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.options !== state.options) {
      return {
        ...state,
        options: props.options,
        history: [
          {
            header: 'options',
            options: props.options,
          },
        ],
      };
    }

    return null;
  }

  componentDidMount() {
    this.updateSoftKeys();
    this.setState({
      optionsRefs: this.createOptionsRefs(this.state.options),
    });
    this.contentRef.current.addEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      history: prevHistory,
      currentFocusIndex: prevCurrentFocusIndex,
    } = prevState;
    const { history, currentFocusIndex } = this.state;
    /**
     * handle focus for: after nav by user
     */
    if (prevCurrentFocusIndex !== currentFocusIndex) {
      this.handleFocus();
    }

    if (history.length && prevHistory.length !== history.length) {
      this.setState({
        optionsRefs: this.createOptionsRefs(
          history[history.length - 1].options
        ),
      });
    }

    this.handleFocus();
  }

  componentWillUnmount() {
    this.unregisterSoftKeys();
    this.element = null;
    this.contentRef.current.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const { scrollTop } = this.contentRef.current;
    const { showScrollUpIndicator } = this.state;
    if (scrollTop > 0 && showScrollUpIndicator === false) {
      this.setState({ showScrollUpIndicator: true });
    } else if (scrollTop === 0 && showScrollUpIndicator === true) {
      this.setState({ showScrollUpIndicator: false });
    }
  };

  onKeyDown(evt) {
    const { key } = evt;
    const {
      options,
      header,
      history,
      hasCancel,
      onCancel,
      enableGoBack,
    } = this.state;
    const option = options[+evt.target.dataset.index];

    switch (key) {
      case 'Enter':
        evt.stopPropagation();
        evt.preventDefault();

        option && option.callback && option.callback();
        if (option.subMenu) {
          const updatedHeader = option.subMenuHeader || header;
          this.setState({
            header: updatedHeader,
            options: option.subMenu,
            history: enableGoBack
              ? [
                  ...history,
                  {
                    header: updatedHeader,
                    options: option.subMenu,
                  },
                ]
              : history,
          });
        } else {
          this.hide();
        }
        break;
      case 'ArrowUp':
      case 'ArrowDown':
        evt.stopPropagation();
        this._nav(key);
        break;
      case 'SoftLeft':
      case 'BrowserBack':
      case 'Backspace':
        if (key === 'SoftLeft' && !hasCancel) {
          return;
        }

        evt.stopPropagation();
        evt.preventDefault();

        if (enableGoBack && history.length > 1) {
          const previousHistory = history.slice(0, -1);
          const previousStates = previousHistory[previousHistory.length - 1];
          this.setState({
            ...previousStates,
            history: previousHistory,
          });
        } else {
          onCancel && onCancel();
          this.hide();
        }
        break;
      default:
        break;
    }
  }

  handleFocus = () => {
    const { currentFocusIndex, options } = this.state;

    if (options.length === 0) {
      // still have to focus on something or the keydown will freeze
      this.element.focus();
    } else {
      const option = options[currentFocusIndex];
      const { id: optionId } = option;
      const optionDOM = this.state.optionsRefs[optionId].current;

      if (optionDOM) {
        optionDOM.focus();
        optionDOM.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  };

  unregisterSoftKeys() {
    SoftKeyStore.unregister(this.element);
  }

  updateSoftKeys() {
    registerSoftKey(
      {
        left: this.state.hasCancel ? 'cancel' : '',
        center: 'select',
        right: '',
      },
      this.element
    );
  }

  createOptionsRefs(options) {
    return options.reduce((acc, option) => {
      acc[option.id] = React.createRef();
      return acc;
    }, {});
  }

  hide() {
    this.props.toggleOptionMenu();
  }

  _nav(direction) {
    const { currentFocusIndex } = this.state;
    const { options } = this.props;
    const nextIndex =
      direction === 'ArrowUp'
        ? (options.length + (currentFocusIndex - 1)) % options.length
        : (currentFocusIndex + 1) % options.length;

    this.setState({ currentFocusIndex: nextIndex });
  }

  render() {
    const _ = navigator.mozL10n.get;
    const {
      customClass,
      header,
      currentFocusIndex,
      optionsRefs,
      showScrollUpIndicator,
    } = this.state;
    const { options } = this.props;

    const _className = customClass
      ? `option-menu-container--${customClass}`
      : 'option-menu-container';

    return (
      <div
        className={_className}
        onKeyDown={e => this.onKeyDown(e)}
        ref={element => {
          this.element = element;
        }}
      >
        <div className="option-menu">
          <div
            id="option-menu-header"
            className="header h1"
            key="translated-header"
          >
            {_(header)}
          </div>
          <div className="container">
            <div
              className={`content p-ul ${
                showScrollUpIndicator ? 'with-indicator' : ''
              }`}
              ref={this.contentRef}
            >
              {this.props.children || (
                <Options
                  options={options}
                  optionsRefs={optionsRefs}
                  currentFocusIndex={currentFocusIndex}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
