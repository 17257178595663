import { LaunchActivityCommand } from 'kaistore-post-messenger/src/commands';
import { MessageSender } from 'web-message-helper';

import { PATH } from '@/constant';
import routeHelper from '@/helper/route-helper';
import analyticsHelper from '@/helper/analytics-helper';
import { AVAILABLE_OPTION_MENU_ITEM, SHARE_TYPE } from './constants';

const launchActivity = (name, data, options = {}) => {
  MessageSender.send(
    new LaunchActivityCommand({
      detail: {
        name,
        data,
        ...options,
      },
    }),
    (success, detail) => {
      console.warn(success, detail);
    }
  );
};

const generateShareFileName = () => {
  // Get from notes app https://git.kaiostech.com/feature-phone-apps/notes/-/blob/master/src/util/utils.js#L110
  const nowDate = new Date();
  const name =
    `store_share_app_` +
    `${nowDate.getFullYear()}` +
    `${`0${nowDate.getMonth() + 1}`.slice(-2)}` +
    `${`0${nowDate.getDate()}`.slice(-2)}` +
    `${`0${nowDate.getHours()}`.slice(-2)}` +
    `${`0${nowDate.getMinutes()}`.slice(-2)}` +
    `${`0${nowDate.getSeconds()}`.slice(-2)}` +
    `${nowDate.getMilliseconds()}` +
    `.note`;

  return name;
};

export const getDefaultShareAppMenuOptions = isRemovableApp =>
  isRemovableApp
    ? [
        // AVAILABLE_OPTION_MENU_ITEM.SHARE_APP,
        AVAILABLE_OPTION_MENU_ITEM.UNINSTALL,
        // TODO: Uncomment these options when corresponding pages finished developing.
        // AVAILABLE_OPTION_MENU_ITEM.PRIVACY_POLICY_DEVELOPER,
        // AVAILABLE_OPTION_MENU_ITEM.CUSTOMER_SUPPORT,
        // AVAILABLE_OPTION_MENU_ITEM.DEVELOPER_WEBSITE,
      ]
    : [
        // AVAILABLE_OPTION_MENU_ITEM.SHARE_APP,
        // TODO: Uncomment these options when corresponding pages finished developing.
        // AVAILABLE_OPTION_MENU_ITEM.PRIVACY_POLICY_DEVELOPER,
        // AVAILABLE_OPTION_MENU_ITEM.CUSTOMER_SUPPORT,
        // AVAILABLE_OPTION_MENU_ITEM.DEVELOPER_WEBSITE,
      ];

export const navigateToSetting = () => routeHelper.route(PATH.SETTING.URL());

export const navigateToDownloadHistory = () => {
  analyticsHelper.sendDownloadHistoryViewEvent('init');
  routeHelper.route(PATH.DOWNLOAD_HISTORY.URL());
};

export const callbackLosingWarning = warnType => {
  switch (warnType) {
    case 'shareAppCallback':
      console.warn(
        'Please create share app Callback by createShareAppCallback'
      );
      break;
    default:
      console.warn('Please provide custom Options Callback');
      break;
  }
};

export const createShareAppCallback = (
  shareType,
  appName,
  manifestURL
) => () => {
  const _ = navigator.mozL10n.get;
  const shareMsg = `${_(
    'checkout-this-app'
  )}! ${appName} https://webstore.kaiostech.com/apps?manifest_url=${manifestURL}`;

  switch (shareType) {
    case SHARE_TYPE.SMS:
      launchActivity('new', {
        body: shareMsg,
        type: shareType,
      });
      break;
    case SHARE_TYPE.MAIL:
      launchActivity('new', {
        url: `mailto:?subject=${_('checkout-this-app')}! ${encodeURIComponent(
          appName
        )}&body=${encodeURIComponent(shareMsg)}`,
        type: shareType,
      });
      break;
    case SHARE_TYPE.SOCIAL_MEDIA_APP:
      launchActivity(
        'share',
        {
          type: 'text/plain',
          blobs: shareMsg,
          filenames: [generateShareFileName()],
        },
        {
          fieldsTransformToBlobs: ['blobs'],
        }
      );
      break;
    default:
      break;
  }
};
