import { MessageSender } from 'web-message-helper';
import { SendHttpRequestCommand } from 'kaistore-post-messenger/src/commands';

/**
 * Remove control characters from response.
 * @param {String} rawString - The raw string of the response.
 * @return {String} string with control characters removed.
 * @public
 */
const removeControlChars = rawString => {
  const ctrlChaRegex = /[\x00-\x1F\x7F-\x9F]/g; // eslint-disable-line
  return rawString.replace(ctrlChaRegex, '');
};

/**
 * Sends HTTP GET requests through postMessage.
 * @param {String} url - The full URL of the requested destination.
 * @param {Object} options - The options to customized the request.
 * @return {Promise} Response of the request.
 * @public
 */
const fetchThroughClient = (url, options = {}) => {
  const { toRemoveControlChars = false, useDeviceInfo = false } = options;
  const detail = {
    method: 'GET',
    url,
    useDeviceInfo,
  };
  return new Promise((resolve, reject) => {
    MessageSender.send(
      new SendHttpRequestCommand({ detail }),
      (success, result) => {
        if (success) {
          const { response: rawResponse } = result;
          const response = toRemoveControlChars
            ? removeControlChars(rawResponse)
            : rawResponse;
          resolve(JSON.parse(response));
        } else {
          const { error } = result;
          const errorWithUrl = { ...error, url };
          reject({ error: errorWithUrl });
        }
      }
    );
  });
};

/**
 * Sends HTTP POST requests through postMessage.
 * @param {String} url - The full URL of the requested destination.
 * @param {Object} options - The options to customized the request.
 * @return {Promise} Response of the request.
 * @public
 */
const postThroughClient = (url, options = {}) => {
  const { useDeviceInfo = false, params, appendAccountInfo = false } = options;
  const detail = {
    method: 'POST',
    params,
    url,
    useDeviceInfo,
    appendAccountInfo,
  };
  return new Promise((resolve, reject) => {
    MessageSender.send(
      new SendHttpRequestCommand({ detail }),
      (success, result) => {
        if (success) {
          resolve(JSON.parse(result.response));
        } else {
          const { error } = result;
          const errorWithUrl = { ...error, url };
          reject({ error: errorWithUrl });
        }
      }
    );
  });
};

export { postThroughClient, fetchThroughClient };
