import { MessageSender } from 'web-message-helper';
import {
  RequestSetCacheCommand,
  RequestGetCacheCommand,
} from 'kaistore-post-messenger/src/commands';

class CacheHelper {
  constructor() {
    this.cacheKey = 'cached_apps_categories';
    this.item = {
      categories: null,
      comboApps: null,
      timestamp: null,
      isCached: false,
    };
    this.shouldUpdateCache = false;
  }

  get isCacheValid() {
    return this.checkCacheValid(this.item);
  }

  checkCacheValid(item) {
    return (
      item &&
      item.isCached &&
      item.categories !== null &&
      item.comboApps !== null
    );
  }

  prepareCachedItem() {
    MessageSender.send(
      new RequestGetCacheCommand({ detail: { key: this.cacheKey } }),
      (success, detail) => {
        if (success && detail && this.checkCacheValid(detail)) {
          this.item = detail;
        }
      }
    );
  }

  setValidCacheItem = (categories, comboApps) => {
    this.item.categories = categories;
    this.item.comboApps = comboApps;
    this.item.isCached = true;
    this.item.timestamp = Date.now();
    this.shouldUpdateCache = true;
  };

  updateCachedItem() {
    if (this.isCacheValid && this.shouldUpdateCache) {
      MessageSender.send(
        new RequestSetCacheCommand({
          detail: {
            key: this.cacheKey,
            item: this.item,
          },
        })
      );
    }
  }

  removeInvalidCategory(categoryCode) {
    if (!this.isCacheValid) {
      return;
    }
    if (!this.item.categories.static) {
      return;
    }
    this.item.categories.static = this.item.categories.static.filter(
      category => category.code !== categoryCode
    );
    this.shouldUpdateCache = true;
    this.updateCachedItem();
  }
}

export default new CacheHelper();
