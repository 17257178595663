import React from 'react';
import cx from 'classnames';

import 'scss/CategoryTabs.scss';

const CategoryTabs = React.forwardRef(
  ({ categories, currentCateIndex }, ref) => (
    <div className="category-tabs" ref={ref.box}>
      <div className="container">
        {categories.map((cate, index) => {
          const classes = cx('tab', {
            selected: currentCateIndex === index,
          });
          return (
            <div
              key={cate.displayName}
              tabIndex="1"
              className={classes}
              ref={ref[cate.code]}
            >
              <span>{cate.displayName}</span>
            </div>
          );
        })}
      </div>
    </div>
  )
);

// fix ESLint false positive error
CategoryTabs.displayName = 'CategoryTabs';

export default CategoryTabs;
