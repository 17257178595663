export const SHARE_TYPE = {
  SMS: 'websms/sms',
  MAIL: 'mail',
  SOCIAL_MEDIA_APP: 'social',
};

export const AVAILABLE_OPTION_MENU_ITEM = {
  PRIVACY_POLICY: 'privacy-policy',
  TERMS_AND_SERVICES: 'terms-and-services',
  NEED_HELP: 'need-help',
  SETTINGS_AND_ACCOUNT: 'settings-and-account',
  SETTINGS: 'settings',
  UNINSTALL: 'uninstall',
  SHARE_APP: 'share-this-app',
  MAIL: 'e-mail',
  MESSAGES: 'messages',
  PRIVACY_POLICY_DEVELOPER: 'privacy-policy-developer',
  CUSTOMER_SUPPORT: 'customer-support',
  DEVELOPER_WEBSITE: 'developer-website',
  SOCIAL_MEDIA: 'social-media',
  DOWNLOAD_HISTORY: 'download-history',
};
