class MozAppHelper {
  // Delete unused description field to release strings
  releaseUnusedInfo(mozAPP) {
    let manifest = mozAPP.manifest || mozAPP.updateManifest;
    const { locales } = manifest;

    delete manifest.description;
    if (locales) {
      for (let locale in locales) {
        if ({}.hasOwnProperty.call(locales, locale)) {
          delete locales[locale].description;
        }
      }
    }
  }
}
export const mozAppHelper = new MozAppHelper();
