import { RequestDeviceInfoCommand } from 'kaistore-post-messenger/src/commands';
import { DeviceInfo } from 'kaistore-post-messenger/src/models';
import { MessageSender } from 'web-message-helper';

class DeviceUtils {
  constructor() {
    this.connection = {};
    this.settings = new Map();
    this.isBookmarkDBSupported = false;
  }

  get isSettingPanelSupported() {
    return this.settings.get('apps.serviceCenter.settingsEnabled') || false;
  }

  requestInfo() {
    return new Promise((resolve, reject) => {
      MessageSender.send(new RequestDeviceInfoCommand(), (success, detail) => {
        if (success) {
          // utilize objectmodel to fill in some default values that are not available from client
          // XXX: detail.setting is a Map. Even the default defined in objectmodel is an object,
          // it is still able to tell if an attribute is defined.
          const deviceInfoWithDefaults = new DeviceInfo(detail);
          // make it as Map
          const newSettings = new Map(
            Object.entries(deviceInfoWithDefaults.settings)
          );

          this.connection = detail.connection;
          this.settings = newSettings;
          resolve();
        } else {
          reject();
        }
      });
    });
  }
}
export const deviceUtils = new DeviceUtils();
