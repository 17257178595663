import APP_TYPES from './constant';
import { APPBRICK_LOCATION } from '@/constant';

const getAppType = app => {
  if (app.isBookmark) {
    return APP_TYPES.BOOKMARK;
  }

  if (app.info.paid === true) {
    return APP_TYPES.PAID_APP;
  }

  if (app.info.paid === false) {
    return APP_TYPES.FREE_APP;
  }

  return APP_TYPES.INVALID;
};

// https://bugzilla.kaiostech.com/show_bug.cgi?id=115407
const getIsAd = (app, location) => {
  const { isSearchAd, isYmalAd } = app.info;
  switch (location) {
    case APPBRICK_LOCATION.YMAL:
      return isYmalAd;
    case APPBRICK_LOCATION.SEARCH:
      return isSearchAd;
    default:
      return false;
  }
};

export { getAppType, getIsAd };
