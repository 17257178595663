import { fetchThroughClient } from './http-request-helper';
import { SPECIAL_CATE_CODE } from '@/constant';
import { deviceUtils } from '@/device-utils';
import { createQuerystringFromParams } from '@/utils';

const _ = navigator.mozL10n.get;
class AppListHelper {
  constructor() {
    this._apiName = {
      apps: '/apps',
      combo: '/apps/combo',
    };
    this._apiURI = this._getApiURI();
    this._defaultParams = this._getDefaultParams();
    this.locale = navigator.mozL10n.language.code || 'en-US';
  }

  _getApiURI() {
    const apiURIKey = 'apps.serviceCenter.microServiceUrl';
    const apiURI = deviceUtils.settings.get(apiURIKey);
    if (!apiURI) {
      console.error(`${apiURIKey} is not found in deviceInfos`);
    }
    return apiURI;
  }

  _getDefaultParams() {
    const { connection, settings } = deviceUtils;
    const params = {
      link: this.isLinkAppSupported,
      bookmark: this.isBookmarkSupported,
      defaultServiceId: connection.defaultServiceId,
      imei: window.IMEI,
    };
    const deviceInfoParams = [...settings]
      .filter(([key]) => key.includes('deviceinfo'))
      .reduce((acc, [key, val]) => {
        const paramKey = key.split('.').pop();
        const paramVal = val.replace(/ /g, '_');
        acc[paramKey] = paramVal;
        return acc;
      }, {});
    return { ...params, ...connection, ...deviceInfoParams };
  }

  get isKcKsfeV1() {
    const supportedKeyword = '/kc_ksfe/v1.0';
    return this._apiURI.includes(supportedKeyword);
  }

  // We have switched from 'apps.serviceCenterUrl' to 'apps.serviceCenter.microServiceUrl'
  // bookmark should be supported but keep this logic for future reference
  // this._apiURI should be "https://api.[ENV.]kaiostech.com/kc_ksfe/v1.0"
  get isBookmarkSupported() {
    return this.isKcKsfeV1;
  }

  get isLinkAppSupported() {
    return this.isKcKsfeV1;
  }

  _getDisplayName(category) {
    const { code } = navigator.mozL10n.language;
    const cate = {
      ...category,
      displayName: category.locales[code] || category.name,
    };
    delete cate.locales;
    return cate;
  }

  _trimComboList(result) {
    const {
      apps: carrierApps = [],
      recommended = [],
      categories: rawCates = [],
    } = result;
    const comboApps = [...carrierApps, ...recommended];
    if (rawCates.length === 0)
      console.error('Categories information not returned');
    // Don't show category with 0 app
    const catesWithApp = rawCates.filter(cate => cate.app_count > 0);
    const staticCates = catesWithApp
      .filter(category => category.type === 1)
      .reduce((categories, category) => {
        /**
         * We should always put premium tab at the beginning of staticCates
         * https://bugzilla.kaiostech.com/show_bug.cgi?id=111211
         */
        const premiumCateCode = 130;
        const displayName = this._getDisplayName(category);

        if (category.code === premiumCateCode) {
          categories.unshift(displayName);
        } else {
          categories.push(displayName);
        }

        return categories;
      }, []);
    const carrierCates = catesWithApp
      .filter(category => category.type === 3)
      .map(category => this._getDisplayName(category));

    const recommendedCate =
      recommended.length > 0
        ? [
            {
              code: SPECIAL_CATE_CODE.RECOMMENDED,
              displayName: _('CAT-recommended'),
            },
          ]
        : [];
    // Order of categories: carrier > update > recommended > static
    const categories = {
      all: [...carrierCates, ...recommendedCate, ...staticCates],
      carrier: carrierCates,
      recommended: recommendedCate,
      static: staticCates,
    };
    return { comboApps, categories };
  }

  _trimAppList(result) {
    const { apps = [] } = result;
    return apps;
  }

  _generateURL(apiName, params) {
    const queryString = createQuerystringFromParams(params);
    return `${this._apiURI}${apiName}${queryString}`;
  }

  fetchAppListByCate({ pageSize, pageNum, cateCode }) {
    const defaultParams = {
      ...this._defaultParams,
      locale: this.locale,
    };
    const nonAllParams = {
      category: cateCode,
      ...(pageNum && { page_num: pageNum }),
      ...(pageSize && { page_size: pageSize }),
    };
    const params =
      cateCode === SPECIAL_CATE_CODE.ALL
        ? defaultParams
        : { ...defaultParams, ...nonAllParams };
    const url = this._generateURL(this._apiName.apps, params);
    return fetchThroughClient(url, {
      toRemoveControlChars: true,
      useDeviceInfo: true,
    })
      .then(result => {
        const { apps, last_page: isLastPage } = result;
        return { apps, isLastPage };
      })
      .catch(error => {
        console.error('error in fetchAppListByCate', error);
        throw { errorMsgId: 'connection-error', status: error.status };
      });
  }

  fetchComboList() {
    const params = {
      ...this._defaultParams,
      page_num: 1,
      page_size: 15,
      locale: this.locale,
    };
    const url = this._generateURL(this._apiName.combo, params);
    return fetchThroughClient(url, {
      toRemoveControlChars: true,
      useDeviceInfo: true,
    })
      .then(result => this._trimComboList(result))
      .catch(result => {
        console.error('error in fetchComboList', result);
        throw { errorMsgId: 'connection-error', status: result.error.status };
      });
  }
}

export default AppListHelper;
