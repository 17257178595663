import { PATH } from '@/constant';
import AppStore from '@/app-store';
import routeHelper from '@/helper/route-helper';

const INLINE_ACTIVITY_NAME = ['inline-open-page', 'inline-open-by-name'];

class MozActivityManager {
  constructor() {
    this.iac_kaipay = 'kaipay-to-kaistore';
    this.taskQueue = [];
    /*
     * While using postResult to exit, we should preventDefault the back key
     * event. Otherwise, it will close the caller app as well.
     */
    this.shouldPostResultToCaller = false;
  }

  // XXX: In the future, we will use these order info to recover the intermittent orders.
  // If the order has been completed, we will remove it after download success.
  handleIAC(iacRequest) {
    // Inter APP Communication with KaiPay
    if (iacRequest.keyword === this.iac_kaipay) {
      const { port } = iacRequest;
      port.onmessage = function onReceivedMessage(evt) {
        const eventData = evt.data;
        const productId = eventData.productID;
        const orderData = eventData.data;
        const orderId = orderData.orderId;

        if (productId && orderId) {
          // add update time to data
          eventData.updateTime = evt.timeStamp;
          // insert or update the order info into indexDB (use "kaipay-{productId}" to be the key)
          asyncStorage.setItem(`${this.iac_kaipay}-${productId}`, eventData);
        }
      }.bind(this);
    }
  }

  saveToQueue(option) {
    if (!option) {
      return;
    }
    this.taskQueue.push(option);
    window.dispatchEvent(new CustomEvent('task-queue-updated'));
  }

  get task() {
    if (this.taskQueue.length === 0) {
      return false;
    }
    return this.taskQueue.pop();
  }

  async findActiveAppByTask(task) {
    const { name: taskName } = task;
    const { type: taskType } = task.data;
    let graphQLQuery = null;
    let application = null;

    switch (taskType) {
      case 'name': {
        const { name } = task.data;
        application = AppStore.findAppByName(name);
        graphQLQuery = { name };
        break;
      }
      case 'url':
      case 'push-app': {
        const { url } = task.data;
        const { searchParams } = new URL(url);
        const activeManifestURL =
          taskName === 'open-deeplink' ? searchParams.get('apps') : url;
        application = AppStore.findAppByManifest(activeManifestURL);
        graphQLQuery = { manifestURL: activeManifestURL };
        break;
      }
      default:
        console.warn(`invalid activity task`, task);
        return null;
    }

    if (application && application.isInRemoteList) {
      return application;
    }

    try {
      const graphQLApp = await AppStore.fetchGraphQL(graphQLQuery);
      return graphQLApp;
    } catch (err) {
      console.warn('failed to fetchApp via graphQL helper', err);
      return null;
    }
  }

  async handleTask(task, handleAppNotFound) {
    const { name: taskName } = task;
    const { type: taskType, adId, autoDownload, pixel } = task.data;
    if (taskName === 'launch-store') {
      this.handleLaunchStore(task);
      return;
    }
    if (taskName === 'silent-launch') {
      return;
    }

    const disposition = INLINE_ACTIVITY_NAME.includes(taskName)
      ? 'inline'
      : 'window';
    let shouldSearchForApp = true;
    let activeApp = null;

    if (taskName === 'open-deeplink') {
      const { url } = task.data;
      const { searchParams } = new URL(url);
      const activeManifestURL = searchParams.get('apps');
      const needPostResult = searchParams.get('postResult') === 'true';
      /*
       * If store is launched via deeplink (app://kaios-store.kaiostech.com)
       * and without having 'apps' param, we should not search for any app
       */
      shouldSearchForApp = !!activeManifestURL;
      this.shouldPostResultToCaller = needPostResult;
    }

    if (shouldSearchForApp) {
      activeApp = await this.findActiveAppByTask(task);
    }

    if (activeApp && activeApp.id) {
      routeHelper.route(
        PATH.PAGE.URL({
          id: activeApp.id,
          autoDownload: taskType === 'push-app' || autoDownload,
          disposition,
          entry: 'activity',
          ...(adId && { adId }),
          ...(pixel && { pixel }),
        })
      );
    } else if (shouldSearchForApp) {
      handleAppNotFound();
    }
  }

  handleLaunchStore(task) {
    const { data } = task;
    const { action, detail } = data;
    switch (action) {
      case 'open-appspanel':
        const { categoryCode } = detail;
        if (!categoryCode) {
          console.warn(
            `[handleLaunchStore] missing detail.categoryCode for open-appspanel`
          );
          break;
        }
        routeHelper.forceUpdateCurrentCategory = true;
        routeHelper.route(PATH.APPS.URL({ categoryCode }));
        break;
      default:
        console.warn(`[handleLaunchStore] unknown data.action ${action}`);
        return;
    }
  }
}

export let mozActivityManager = new MozActivityManager();
