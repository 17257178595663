import debounce from 'lodash.debounce';
import { postThroughClient } from '@/helper/http-request-helper';
import { deviceUtils } from '../device-utils';
import UserInterfaceHelper from '@/helper/user-interface-helper';

const EVENT_TYPE_INITIALS = {
  STORE_SEARCH: 'store_search',
  STORE_APP_VIEW: 'store_app_view',
  STORE_APP: 'store_app',
  DOWNLOAD_HISTORY_VIEW: 'store_download_history_view',
};

const YMAL_FIELDS = {
  CURRENT_SPONSORED_YMAL_LIST: 'store_app_detail_ymal_sponsored_app_list',
  CURRENT_NON_SPONSORED_YMAL_LIST: 'store_app_detail_ymal_rec_app_list',
  SOURCE_SPONSORED_YMAL_LIST:
    'referral_store_app_detail_ymal_sponsored_app_list',
  SOURCE_NON_SPONSORED_YMAL_LIST: 'referral_store_app_detail_ymal_rec_app_list',
  SOURCE_YMAL_REC_ID: 'referral_store_app_detail_ymal_rec_id',
};

const SEARCH_FIELDS = {
  RESULT_INDEX: 'referral_store_search_result_index',
  SEARCH_QUERY_ID: 'referral_store_search_query_id',
};

const REFERRAL_TYPES = {
  STORE_SEARCH: 'store_search',
  APP_DETAIL_YMAL: 'store_app_detail_ymal',
  DOWNLOAD_HISTORY: 'store_options_download_history',
};

class AnalyticsHelper {
  constructor() {
    this._apiUri = null;
    this._apiName = '/store';
    this._url = null;
    this.storeVersion = null;
    this.browsedHistory = [];
    this.tabInfo = {
      firstTabCode: '',
      currentTab: '',
    };
    this.isSendingStoreClose = false;
    this.isYmalBrowsing = false;
    /*
     * the queueEvents would be an object contains logs. for example:
     * {
     *   store_app_view_lj6skNdORZPHqWfrqSwY: [
     *     { event_name: 'store_app_view_init, ... },
     *     { event_name: 'store_app_view_done, ... },
     *   ],
     *   store_app_install_hXMhsZFS0I5HdxAfbQlG: [
     *     { event_name: 'store_app_install_init, ... },
     *   ],
     *   store_app_install_ahjIoexxxxxLwzAfBieK: [
     *     { event_name: 'store_app_install_init, ... },
     *   ],
     * }
     */
    this.queuedEvents = {};
    this.searchRequestId = '';
    this.lastAppAction = {
      action: '',
      appId: '',
    };

    // for sponsored/ad app's installation events
    this.sponsoredApp = {
      adId: '',
    };
    this._postCancelEvent = debounce(this._postCancelEvent, 300);

    this.setYmalReferralInfo();
    this.setAppViewInitData();
    this.setEntryInfo();
    this.setIsSponsoredAppInstalling();
  }

  init(version) {
    this.storeVersion = version;
    this._apiUri = deviceUtils.settings.get('apps.analyticsEventBaseUrl');
    this._url = `${this._apiUri}${this._apiName}`;
  }

  setAppViewInitData = () => {
    this.viewedAppInfo = {
      cacheTimestamp: 0,
      app: {},
      ymalApps: [],
    };
  };

  setYmalReferralInfo = () => {
    this.ymalInfo = {
      recommendationId: '',
      ymalAppId: '',
      ymalSponsoredList: [],
      ymalNaturalList: [],
      requestTime: 0,
    };
  };

  setEntryInfo = () => {
    this.entryInfo = {
      appOrder: '',
      entry: '',
      adId: undefined,
    };
  };

  setIsSponsoredAppInstalling = () => {
    this.sponsoredApp = {
      adId: '',
    };
  };

  /**
   * Set isYmalBrowsing to true when navigate ymals false when back to applist
   * @param {Boolean} val
   * @public
   */
  setIsYmalBrowsing = (val = false) => {
    this.isYmalBrowsing = val;
  };

  setSearchRequestId = requestId => {
    this.searchRequestId = requestId;
  };

  saveViewedTab(tabInfo) {
    const { cateCode, firstTabCode } = tabInfo;
    this.tabInfo.currentTab = cateCode;
    this.tabInfo.firstTabCode = firstTabCode;
  }

  saveViewedApp(appInfo = {}) {
    const numOfViewedCategories = Object.keys(UserInterfaceHelper.cateHistory)
      .length;
    const { appOrder, appId, categoryCode } = appInfo;
    const matchPreviousCate = categoryCode === this.tabInfo.currentTab;
    const previousRecord =
      this.browsedHistory.length > 0 &&
      this.browsedHistory[this.browsedHistory.length - 1];
    const newRecord = {
      tab: categoryCode,
      app_id: appId,
      app_order: appOrder,
      device_utc: Date.now(),
    };

    const isFirstFocusedAppInHistory =
      numOfViewedCategories === 0 &&
      categoryCode === this.tabInfo.firstTabCode &&
      !previousRecord;
    const isFirstFocusedAppInSession = !previousRecord && matchPreviousCate;
    const isDifferentApp = previousRecord && appId !== previousRecord.app_id;

    if (
      isFirstFocusedAppInHistory ||
      isFirstFocusedAppInSession ||
      isDifferentApp
    ) {
      this.browsedHistory.push(newRecord);
    }
  }

  saveBackNavigation = info => {
    const { appId, deviceUtc } = info;
    this.browsedHistory.push({
      app_id: appId,
      device_utc: deviceUtc,
      navigation: 'back',
    });
  };

  saveYmalInfo = ymalInfo => {
    const { ymalAppId, ymalAppsList, requestTime, recommendationId } = ymalInfo;

    if (ymalAppId !== undefined) {
      this.ymalInfo.recommendationId = recommendationId;
      this.ymalInfo.ymalAppId = ymalAppId;
      this.ymalInfo.requestTime = requestTime;

      const { sponsored, natural } = this._splitYmalList(ymalAppsList);
      this.ymalInfo.ymalSponsoredList = sponsored;
      this.ymalInfo.ymalNaturalList = natural;

      this.setIsYmalBrowsing(true);
    }
  };

  postData(params, { appendAccountInfo = false }) {
    return postThroughClient(this._url, {
      useDeviceInfo: true,
      params,
      appendAccountInfo,
    }).catch(error => {
      throw new Error(JSON.stringify(error));
    });
  }

  /**
   * Receives app action events (install/update) and compose event body
   * @param {Object} data - includes event_name, app_id, and app_version
   * @public
   */
  postAppActionEvent(data) {
    const { app_id: appId, event_name } = data;
    const { entry, adId, ymalAdId, ...requiredEventData } = data;
    const logInfo = this._appendSharedFields(requiredEventData);
    const isCancelAction = this._ifIsCancelAction(logInfo.event_name);
    const isInstallEvent = event_name.indexOf('_install') > -1;
    const isDoneInstalling =
      event_name.endsWith('_install_done') ||
      event_name.endsWith('_install_failed');

    // Keep the app's ad id in remoteInfo from AppDetailPanel and use it in
    // install_done/faileed
    if (entry === 'search' && adId !== undefined) {
      this.sponsoredApp.adId = adId;
    } else if (entry === 'apps' && ymalAdId !== undefined) {
      this.sponsoredApp.adId = ymalAdId.toString();
    }

    if (isInstallEvent && this.sponsoredApp.adId !== '') {
      logInfo.referral_store_sponsored_ad_id = this.sponsoredApp.adId;

      if (isDoneInstalling) {
        this.setIsSponsoredAppInstalling();
      }
    }

    if (isCancelAction) {
      this._postCancelEvent(logInfo);
    } else {
      this._logTracker(logInfo, appId);
    }
  }

  postSearchEvent(data) {
    const { keyword, ...eventData } = data;
    const logInfo = this._appendSharedFields(eventData);
    this._logTracker(logInfo, keyword);
  }

  /**
   * Get shared fields for store_search_panel_open_init/done events
   * and call this._logTracker
   * @param {Object} data - event_name = tore_search_panel_open_init/done from
   * AppsPanel and SearchPanel, respectively
   * @public
   */
  postSearchPanelOpenEvent = data => {
    const logInfo = this._appendSharedFields(data);
    this._logTracker(logInfo);
  };

  /**
   * Get shared fields for store_app_view_init/done events and call this._logTracker
   * @param {Object} data - store_app_view_init/done related fields from
   * app page including app_id, device_utc, and event_name
   * @private
   */
  postAppViewEvent(data) {
    const { app_id: appId } = data;
    const logInfo = this._appendSharedFields(data);

    this._logTracker(logInfo, appId);
  }

  /**
   * Send store_download_history_view_init/done event
   * @param {String} eventStatus - init or done
   * @public
   */
  sendDownloadHistoryViewEvent = eventStatus => {
    const logInfo = this._appendSharedFields({
      event_name: `${EVENT_TYPE_INITIALS.DOWNLOAD_HISTORY_VIEW}_${eventStatus}`,
    });

    this._logTracker(logInfo);
  };

  /**
   * Get shared fields for store_close event and postData
   * @public
   */
  postStoreCloseEvent() {
    if (!this.isSendingStoreClose) {
      const logInfo = this._appendSharedFields({
        event_name: 'store_close',
      });

      this.isSendingStoreClose = true;
      this.postData(logInfo, { appendAccountInfo: true }).then(() => {
        this.isSendingStoreClose = false;
      });
    }
  }

  /**
   * Get shared fields for cancellation events and postData
   * Cancellation events can be store_app_uninstall_init,
   * store_app_install_cancel_init and store_app_update_cancel_init
   * @param {Object} data - logInfo includes event_name, app_id, app_version,
   * connection_type, device_utc, store_version, and user_journey
   * @private
   */
  _postCancelEvent(data) {
    this.postData(data, { appendAccountInfo: true });
  }

  /**
   * Receives data when autoupdate setting changes and post events
   * @param {Object} data - includes autoupdate setting, login status, and download history
   * @public
   */
  postAutoUpdateSettingChangeEvent = data => {
    const {
      autoupdate_setting,
      is_kaiaccount_signed_in,
      number_of_application,
    } = data;
    const logInfo = this._appendSharedFields({
      event_name: 'store_autoupdate_setting_update',
      autoupdate_setting,
      download_history_status: {
        is_kaiaccount_signed_in,
        number_of_application,
      },
    });

    this.postData(logInfo, { appendAccountInfo: true });
  };

  /**
   * Save viewed app and ymal info for store_app_view_init
   * @param {Object} data - has 1) timestamp,
   * 2) (optional) appOrder + entry for store search,
   * 3) and app, ymalApps, and (optional) errorDetail (when GraphQL completes)
   * @public
   */
  updateAppViewInitInfo = data => {
    const {
      appOrder,
      entry,
      cacheTimestamp,
      app,
      ymalApps,
      errorDetail,
      adId,
    } = data;
    if (entry === 'search' || entry === 'downloadHistory') {
      this.entryInfo.entry = entry;
      this.entryInfo.appOrder = appOrder;
      this.entryInfo.adId = adId;
    }

    if (cacheTimestamp > 0) {
      this.viewedAppInfo = {
        ...this.viewedAppInfo,
        cacheTimestamp,
      };
    } else {
      this.viewedAppInfo = {
        ...this.viewedAppInfo,
        app,
        ymalApps,
      };

      if (errorDetail) {
        this.viewedAppInfo.errorDetail = errorDetail;
      }
    }
  };

  /**
   * Compose store_app_view_done content and call this.postAppViewEvent
   * @param {Object} app - an model/application object
   * @public
   */
  sendAppViewDone = app => {
    const logInfo = {
      event_name: 'store_app_view_done',
      app_id: app.id,
      app_version: app.info.version,
    };
    this.postAppViewEvent(logInfo);
  };

  /**
   * Compose store_app_view_init content and call this.postAppViewEvent
   * An store_app_view_init would have
   * {String} event_name
   * {Boolean} is_sponsored
   * {Array} store_app_detail_ymal_rec_app_lis
   * {Array} store_app_detail_ymal_sponsored_app_list
   * {String} tab_code
   * {Array} user_journey
   * (optional) {String} referral_type
   * (optional) {String} referral_store_search_query_id
   * (optional) {String} referral_store_search_result_index
   * (optional) {String} referral_store_app_detail_ymal_rec_id
   * (optional) {Array} referral_store_app_detail_ymal_sponsored_app_list
   * (optional) {Array} referral_store_app_detail_ymal_rec_app_list
   * @public
   */
  sendAppViewInit = () => {
    const { cacheTimestamp, app, errorDetail } = this.viewedAppInfo;
    const currentYmals = this._generateYmalFields();
    let logInfo = {
      event_name: 'store_app_view_init',
      app_id: app.id,
      app_version: app.info.version,
      device_utc: cacheTimestamp,
      is_sponsored: false,
    };

    const isFromSearch = this.entryInfo && this.entryInfo.entry === 'search';
    const isFromYmal = this.ymalInfo.ymalAppId !== '';
    const isFromDownloadHistory =
      this.entryInfo && this.entryInfo.entry === 'downloadHistory';
    const hasTabInfo = this.tabInfo.currentTab !== undefined;

    // attach ymal list on the same page
    logInfo = {
      ...logInfo,
      ...currentYmals,
    };

    // attach search fields
    if (isFromSearch) {
      const searchFields = this._generateSearchFields();
      logInfo = {
        ...logInfo,
        ...searchFields,
      };

      if (app.info.isSearchAd) {
        logInfo.is_sponsored = true;
        logInfo.referral_store_sponsored_ad_id = this.entryInfo.adId;
      }
    } else if (isFromDownloadHistory) {
      logInfo.referral_type = REFERRAL_TYPES.DOWNLOAD_HISTORY;
    } else if (this.isYmalBrowsing) {
      logInfo.referral_type = REFERRAL_TYPES.APP_DETAIL_YMAL;

      if (app.info.isYmalAd) {
        logInfo.is_sponsored = true;
        logInfo.referral_store_sponsored_ad_id =
          app.remoteInfo.ad_id && app.remoteInfo.ad_id.toString();
      }
    }

    // attach ymal list on the previous page
    if (isFromYmal) {
      const sourceYmalData = this._generatePrevYmalFields();

      logInfo = {
        ...logInfo,
        ...sourceYmalData,
      };
    }

    if (hasTabInfo) {
      logInfo.tab_code = this.tabInfo.currentTab;
    }

    if (errorDetail) {
      logInfo.error_detail = errorDetail;
    }

    this.postAppViewEvent(logInfo);
    this.setAppViewInitData();
    this.setEntryInfo();
  };

  /**
   * Create search-related fields
   * @return {Object} fields - have referral_type,
   * referral_store_search_query_id, and referral_store_search_query_id
   * @private
   */
  _generateSearchFields() {
    const fields = {
      referral_type: EVENT_TYPE_INITIALS.STORE_SEARCH,
    };

    fields[SEARCH_FIELDS.RESULT_INDEX] = this.entryInfo.appOrder;
    fields[SEARCH_FIELDS.SEARCH_QUERY_ID] = this.searchRequestId;

    return fields;
  }

  /**
   * Get ymal related fields on the current app detail
   * @return {Object} ymals - have store_app_detail_ymal_sponsored_app_list
   * and store_app_detail_ymal_rec_app_list
   * @private
   */
  _generateYmalFields() {
    const { ymalApps } = this.viewedAppInfo;
    const ymals = {};

    const { sponsored, natural } = this._splitYmalList(ymalApps);
    ymals[YMAL_FIELDS.CURRENT_NON_SPONSORED_YMAL_LIST] = natural;
    ymals[YMAL_FIELDS.CURRENT_SPONSORED_YMAL_LIST] = sponsored;

    return ymals;
  }

  /**
   * Get ymal related fields on the previous app detail
   * @return {Object} ymals - have referral_type,
   * referral_store_app_detail_ymal_rec_id,
   * referral_store_app_detail_ymal_sponsored_app_list
   * and referral_store_app_detail_ymal_rec_app_list
   * @private
   */
  _generatePrevYmalFields() {
    const ymals = {
      device_utc: this.ymalInfo.requestTime,
    };

    ymals[YMAL_FIELDS.SOURCE_YMAL_REC_ID] = this.ymalInfo.recommendationId;
    ymals[YMAL_FIELDS.SOURCE_SPONSORED_YMAL_LIST] = [
      ...this.ymalInfo.ymalSponsoredList,
    ];
    ymals[YMAL_FIELDS.SOURCE_NON_SPONSORED_YMAL_LIST] = [
      ...this.ymalInfo.ymalNaturalList,
    ];

    this.setYmalReferralInfo();
    return ymals;
  }

  /**
   * Separate sponsored from non_sponsored apps
   * @param {Array} appList - list of app objects
   * @return {Object} contains sponsored and non_sponsored array of apps
   * @private
   */
  _splitYmalList(appList) {
    const sponsored = [];
    const natural = [];

    if (appList && appList.length > 0) {
      appList.forEach(app => {
        const { isYmalAd } = app.info;
        if (isYmalAd) {
          sponsored.push(app.id);
        } else {
          natural.push(app.id);
        }
      });
    }

    return { sponsored, natural };
  }

  /**
   * Keep those events which have to go in pairs and call postData when ready
   * Including app view, search, install, and update
   * @param {Object} logInfo - event object
   * @param {String} pseudoId- appId or keyword (search)
   * @private
   */
  _logTracker(logInfo, pseudoId = '0') {
    const { event_name: eventName } = logInfo;
    const eventId = this._getEventIdentifier(eventName, pseudoId);
    let targetLogs = null;
    let logIndex = null;

    if (this.queuedEvents[eventId] === undefined) {
      this.queuedEvents[eventId] = [];
    }
    targetLogs = this.queuedEvents[eventId];

    logIndex = targetLogs.findIndex(log => log.event_name === eventName);
    if (logIndex === -1) {
      targetLogs.push(logInfo);
    } else {
      targetLogs.splice(logIndex, 1, logInfo);
    }

    if (targetLogs.length > 1) {
      targetLogs.forEach(log => {
        this.postData(log, { appendAccountInfo: true });
      });
      delete this.queuedEvents[eventId];
    }
  }

  _appendSharedFields(data) {
    const connectionType = deviceUtils.connection.type;
    const { event_name: eventName, device_utc: deviceUtc } = data;
    const logInfo = Object.assign(data, {
      store_version: this.storeVersion,
      device_utc: deviceUtc || Date.now(),
      connection_type: connectionType,
    });

    if (eventName.endsWith('init') || eventName === 'store_close') {
      logInfo.user_journey = [...this.browsedHistory];
      this._clearUserJourney();
    }

    return { ...logInfo };
  }

  /**
   * Generate pseudo event ids so that it's easier to group events that need to
   * to sent in pairs like app search and view/install/update.
   * An example pseudo event id: store_app_view_lj6skNdORZPHqWfrqSwY
   * @param {String} eventName
   * @param {String} pseudoId- appId or keyword (search)
   * @return {String} eventName plus pseudoId
   * @private
   */
  _getEventIdentifier(eventName, pseudoId) {
    let eventType = null;
    let eventId = null;

    if (eventName.startsWith(EVENT_TYPE_INITIALS.STORE_SEARCH)) {
      eventType = EVENT_TYPE_INITIALS.STORE_SEARCH;
    } else if (eventName.startsWith(EVENT_TYPE_INITIALS.STORE_APP_VIEW)) {
      eventType = EVENT_TYPE_INITIALS.STORE_APP_VIEW;
    } else if (eventName.startsWith(EVENT_TYPE_INITIALS.STORE_APP)) {
      /*
       * eventType for app actions can be store_app_install_init/done/fail
       * or store_app_update_init/done/fail
       */
      eventType = eventName
        .split('_')
        .slice(0, 3)
        .join('_');
    }

    eventId = `${eventType}_${pseudoId}`;
    return eventId;
  }

  /**
   * Check if an event is a cancellation
   * @param {String} eventName
   * @return {Boolean} whether eventName is store_app_uninstall_init,
   * store_app_install_cancel_init or store_app_update_cancel_init
   * @private
   */
  _ifIsCancelAction(eventName) {
    return (
      eventName.endsWith('uninstall_init') || eventName.endsWith('cancel_init')
    );
  }

  _clearUserJourney() {
    this.browsedHistory = [];
  }
}

export default new AnalyticsHelper();

export const sendAdTrackingPixel = pixel => {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', pixel);
  xhr.onload = e => {
    const statusCode = e.target.status;
    if (statusCode < 200 || statusCode >= 400) {
      throw new Error('ad-tracking-error');
    }
  };
  xhr.onerror = () => {
    throw new Error('ad-tracking-error');
  };
  xhr.send();
};
