import 'scss/AppBrick.scss';

import React, { Component } from 'react';
import ProgressBar from 'progress-bar';
import cx from 'classnames';

import Marquee from '@/component/Marquee';
import { isOverflown } from '@/helper/user-interface-helper';
import { loadImage } from '@/helper/image-loader';
import RichContentHelper from '@/helper/richcontent-helper';
import { APPBRICK_LOCATION } from '@/constant';

import { StatusTag, AdTag } from './component';
import { getAppType, getIsAd } from './utils';
/*
* This is a magic number for fitting UX.
* In general case, network speed is stable and fast,
* and average app's size is between 2MB ~ 5MB.
* So first progress event from gecko might be smaller than 1%,
* then next event will be finished event.
* So we made `PROGRESS_BASE_LEVEL` as mock progress at first event.
*/

const PROGRESS_BASE_LEVEL = 13;

const _ = navigator.mozL10n.get;

class AppBrick extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showProgressBar: false,
      progressState: 0,
      progressInfinite: true,
      titleOverflow: false,
    };
  }

  componentDidMount() {
    if (this.element) {
      // Add loaded class if the real icon loaded successfully.
      const iconDiv = this.element.querySelector('.icon-wrapper');
      const iconImg = iconDiv.querySelector('img');

      iconImg.onload = () => {
        iconDiv.classList.add('loaded');
      };
      // Handle progress bar
      this.mountProgressBar();
      requestAnimationFrame(() => {
        this.setTitleOverflow();
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { app, appOrder } = this.props;
    const { pinned } = app.state;
    const { downloading } = nextProps.app.state;
    const { isPurchased, isInstalled } = nextProps.app;
    const { titleOverflow, showProgressBar } = nextState;
    const { mozAPP } = nextProps;

    if (app.isBookmark) {
      // update elements when pin or unpin the bookmark
      if (pinned !== nextProps.app.state.pinned) {
        return true;
      }
    } else {
      // update app's status which is purchased.
      if (isPurchased !== this.props.app.isPurchased) {
        return true;
      }
      // update elements when download started or download complete.
      if (
        (!this.state.showProgressBar && downloading) ||
        (this.state.showProgressBar && !downloading)
      ) {
        return true;
      }

      // If we get mozAPP, then update the elements.
      if (mozAPP) {
        return true;
      }

      if (isInstalled !== this.props.app.isInstalled) {
        return true;
      }
    }

    if (this.state.titleOverflow !== titleOverflow) {
      return true;
    }

    if (this.state.showProgressBar !== showProgressBar) {
      return true;
    }

    if (appOrder !== nextProps.appOrder) {
      return true;
    }

    if (app.info.size !== nextProps.app.info.size) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps) {
    const progressBarStateChanged =
      this.state.showProgressBar !== this.props.app.state.downloading;
    const mozAPPLateBound = !prevProps.mozAPP && this.props.mozAPP;
    if (progressBarStateChanged || mozAPPLateBound) {
      this.mountProgressBar();
    }

    if (this.state.progressState === 100) {
      this.switchProgress(false);
    }

    if (
      this.props.mozAPP &&
      prevProps.mozAPP &&
      this.props.app.state.downloading &&
      this.props.mozAPP.progress !== prevProps.mozAPP.progress
    ) {
      this.monitorProgress();
    }
  }

  mountProgressBar() {
    const { app } = this.props;
    if (app.isBookmark) {
      // do something for bookmark without mount progress bar.
    } else {
      this.setupProgress();
    }
  }

  setupProgress() {
    const { downloading } = this.props.app.state;
    // If app is downloading and never start to monitor progress, then do it.
    if (downloading) {
      this.monitorProgress();
    } else {
      this.stopMonitorProgress();
    }
  }

  monitorProgress() {
    const { mozAPP } = this.props;
    if (mozAPP) {
      if (mozAPP.downloadSize === 0) {
        this.progressAutoPlay(true);
        return;
      }
      // reset autoplay
      this.progressAutoPlay(false);

      const progress = Math.floor(
        (this.props.mozAPP.progress / this.props.mozAPP.downloadSize) * 100
      );
      const implicitProgressLevel =
        this.props.mozAPP.progress > 0 && progress < PROGRESS_BASE_LEVEL;
      this.setState({
        progressState: implicitProgressLevel ? PROGRESS_BASE_LEVEL : progress,
      });
    } else {
      // If it is a hosted APP, then mozAPP will be null.
      this.progressAutoPlay(true);
    }
  }

  stopMonitorProgress() {
    const { app, mozAPP } = this.props;
    const { updatable } = app.state;
    const { showProgressBar } = this.state;

    const downloadFailed = showProgressBar && mozAPP.downloadError;
    const updateFailed = showProgressBar && updatable;
    const downloadStopped = mozAPP && !mozAPP.manifest;
    const downloadSuccess = showProgressBar && app.isInstalled;

    if (downloadFailed || updateFailed || downloadStopped) {
      this.switchProgress(false);
      return;
    }

    if (downloadSuccess) {
      this.setState({
        progressState: 100,
      });
    }
  }

  progressAutoPlay(enable) {
    this.setState({ showProgressBar: true, progressInfinite: enable });
  }

  switchProgress(enable) {
    this.setState({ showProgressBar: enable });
    if (enable === false) {
      // reset to zero.
      this.setState({ progressState: 0 });
    }
  }

  generateIcon(app) {
    const { info } = app;
    const iconClasses = cx('icon-wrapper', {
      'bookmark-icon': app.isBookmark,
    });

    const iconElement = (
      <div className={iconClasses}>
        <img className="app-icon" src={info.icon} alt="" />
      </div>
    );

    return iconElement;
  }

  getAppPopularityInfo(app) {
    const isUsedInDetailPage = this.props.location === APPBRICK_LOCATION.DETAIL;
    const totalLikes = '2.3K'; // use real number when it's available

    const iconClasses = cx({
      'app-liked-icon': app.state.liked,
      'app-unliked-icon-detail-page': isUsedInDetailPage,
      'app-unliked-icon-list-page': !app.state.liked && !isUsedInDetailPage,
    });

    return (
      <div className="app-popularity-wrapper">
        <div className={iconClasses} />
        <span>{totalLikes}</span>
      </div>
    );
  }

  setTitleOverflow() {
    if (isOverflown(this.infoWrapper) && this.state.titleOverflow === false) {
      this.setState({
        titleOverflow: true,
      });
    }
  }

  onFocus = () => {
    this.setTitleOverflow();
    console.error(`[store focus debug brick onFocus]`);
    if (this.props.focusHandler) {
      this.props.focusHandler();
    }
  };

  // XXX: create this new method which is only used by AppListView in AppsPanel
  // to not affect other components that are using AppBrick
  handleFocus = async (shouldScrollIntoView = true) => {
    this.setTitleOverflow();
    console.error(`[store focus debug brick handleFocus]:${this.element}`);
    if (shouldScrollIntoView) {
      console.error(`[store focus debug brick shouldScrollIntoView]`);
      this.element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
    this.element.focus();
    RichContentHelper.getEnable().then(enable => {
      if (enable) {
        loadImage(this.element, 'bg');
      }
    });
  };

  onBlur = () => {
    // remove marquee for unfocused appBrick except for appBrick in Detail
    if (this.props.location !== APPBRICK_LOCATION.DETAIL) {
      this.setState({ titleOverflow: false });
    }
  };

  render() {
    const { app, location, hasProgressBar, shouldShowAppSize } = this.props;
    const { titleOverflow, showProgressBar } = this.state;
    const { bg, localized, version, unitPrice, type } = app.info;
    const { name, subtitle } = localized;
    const appType = getAppType(app);
    const isAd = getIsAd(app, location);
    const iconElement = this.generateIcon(app);
    const isUsedInDetail = location === APPBRICK_LOCATION.DETAIL;
    // let popularityElement = app.isBookmark ? null : this.getAppPopularityInfo(app); // enable it when the backend is ready.
    const style = {
      backgroundImage: bg && isUsedInDetail ? `url(${bg})` : null,
    };
    const containerClasses = cx('brick', {
      'bg-loaded': isUsedInDetail && bg,
    });
    const infoClasses = cx('info', {
      full: !isUsedInDetail,
    });
    const progressBoxClasses = cx('progress-box', {
      hide: !showProgressBar,
    });

    return (
      <div
        className={containerClasses}
        tabIndex="1"
        data-id={app.id}
        data-bg-image={bg}
        data-type={type}
        data-version={version}
        data-app-order={this.props.appOrder}
        ref={element => {
          this.element = element;
        }}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        style={style}
      >
        <div className="info-box">
          {iconElement}
          <section
            className={infoClasses}
            ref={element => {
              this.infoWrapper = element;
            }}
          >
            {<AdTag isAd={isAd} />}
            {titleOverflow ? (
              <Marquee>
                <h2>{name}</h2>
              </Marquee>
            ) : (
              <h2>{name}</h2>
            )}
            {/* enable it when the backend is ready. */}
            {/* {popularityElement} */}
            {(!hasProgressBar || !app.state.downloading) &&
              !isUsedInDetail && (
                <p>{app.isBookmark ? _('website') : subtitle}</p>
              )}
            {hasProgressBar && (
              <div className={progressBoxClasses}>
                <ProgressBar
                  infinite={this.state.progressInfinite}
                  percentage={this.state.progressState}
                />
              </div>
            )}
            {shouldShowAppSize && (
              <div className="size">
                <span data-icon="download" />
                {app.info.size}
              </div>
            )}
          </section>
        </div>
        <StatusTag
          appType={appType}
          unitPrice={unitPrice}
          isPinned={app.state.pinned}
          isInstalled={app.isInstalled}
          isUpdatable={app.state.updatable}
          isDownloading={app.state.downloading}
          isPurchased={app.isPurchased}
          isAd={isAd}
          isUsedInDetail={isUsedInDetail}
        />
      </div>
    );
  }
}

AppBrick.defaultProps = {
  hasProgressBar: true,
  shouldShowAppSize: false,
  location: APPBRICK_LOCATION.APPLIST,
};

export default AppBrick;
