import { commandList } from 'kaistore-post-messenger';
import { mozAppsEvents as constMozAppsEvents } from 'kaistore-post-messenger/lib/constants';
import AppStore from '@/app-store';
import UserInterfaceHelper from '@/helper/user-interface-helper';
import { mozActivityManager } from '@/mozactivity-manager';
import Account from '../account';
import analyticsHelper from '@/helper/analytics-helper';

const receiveMessage = event => {
  const { action, detail } = event.detail;
  switch (action) {
    case commandList.MOZAPP_UPDATE: {
      const { manifestURL, mozApp, mozAppEvent, mozAppsEvent } = detail;
      const app = AppStore.findAppByManifest(manifestURL);
      if (!app) {
        return;
      }

      app.mozAPP = mozApp;
      AppStore.publish('appstore:change');

      if (mozAppsEvent === constMozAppsEvents.INSTALL) {
        app.handleInstall(mozAppEvent);
      } else if (mozAppsEvent === constMozAppsEvents.UNINSTALL) {
        app.handleUninstall();
      } else if (mozAppsEvent === constMozAppsEvents.UPDATE) {
        app.handleUpdate(mozAppEvent);
        AppStore.handleUpdate(manifestURL, mozAppEvent);
      }
      break;
    }
    case commandList.BOOKMARK_UPDATE: {
      const { pin, url, silent } = detail;
      const bookmark = AppStore.findBookmarkByUrl(url);
      if (bookmark) {
        bookmark.handleBookmarkUpdate(pin, silent);
      }
      break;
    }
    case commandList.TASK_QUEUE_UPDATE: {
      mozActivityManager.saveToQueue(detail);
      break;
    }
    case commandList.ACCOUNT_EVENT: {
      const { event: e, data } = detail;
      Account.handleEvent(e, data);
      break;
    }
    case commandList.REQUEST_RELOAD: {
      window.location.reload();
      break;
    }
    case commandList.NOTIFY_STORE_UPDATABLE: {
      const { isUpdatable } = detail;
      AppStore.storeUpdatable = isUpdatable;
      break;
    }
    case commandList.NOTIFY_STORE_CLOSE: {
      analyticsHelper.postStoreCloseEvent();
      break;
    }
    case commandList.IFRAME_HAS_FOCUS: {
      UserInterfaceHelper.UIHasFocus = detail;

      const { UIHasFocus, focusElement } = UserInterfaceHelper;
      if (UIHasFocus && focusElement) {
        focusElement.focus();
      }
      break;
    }
    default:
      console.warn(`This ${action} action is not supported. Details: ${event}`);
      break;
  }
};

export default receiveMessage;
