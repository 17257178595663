import { fetchThroughClient } from './http-request-helper';
import { deviceUtils } from '@/device-utils';
import UserInterfaceHelper from '@/helper/user-interface-helper';
import { createQuerystringFromParams } from '@/utils';

/** A class to handle search related operations.
 */
class SearchHelper {
  /**
   * Initializes variables.
   */
  constructor() {
    this._apiVersion = '/v3';
    this._apiName = '/_search';
    this._searchRecord = {
      apps: [],
      relatedApps: [],
      keyword: '',
      errorMsg: '',
      isShowingPopularSearch: true,
    };
  }

  init() {
    this._searchBaseUrl = this.formatSearchBaseUrl(
      deviceUtils.settings.get('apps.searchBaseUrl')
    );
    this._defaultParams = this._getDefaultParams();
  }

  set searchRecord(state) {
    this._searchRecord = state;
  }

  get searchRecord() {
    return this._searchRecord;
  }

  setBrowsingHistory(history) {
    UserInterfaceHelper.cateHistory.search = history;
  }

  getBrowsingHistory() {
    return UserInterfaceHelper.cateHistory.search;
  }

  formatSearchBaseUrl(url) {
    // FIXME: It was a mistake to include version in apps.searchBaseUrl as https://search.kaiostech.com/v1.0.
    // It should be without version, like 'https://search.kaiostech.com'
    // Remove '/v1.0' for backward compatibility
    return url.replace('/v1.0', '');
  }

  resetSearchRecord() {
    this.searchRecord = {
      apps: [],
      relatedApps: [],
      keyword: '',
      errorMsg: '',
      isShowingPopularSearch: true,
    };
    this.setBrowsingHistory(0);
  }

  /**
   * Get default params for the following searches
   * @return {Object} The default param object.
   * @private
   */
  _getDefaultParams() {
    // Bug 101754#c2, set 20 as default, and no need to handle pagination.
    const PAGE_SIZE = 20;
    const { connection, settings } = deviceUtils;
    const {
      defaultServiceId,
      imei,
      currentMCC,
      currentMNC,
      currentMCC2,
      currentMNC2,
    } = connection;
    const communicationParams = {
      imei: imei || '',
      mcc: defaultServiceId === 0 ? currentMCC : currentMCC2,
      mnc: defaultServiceId === 0 ? currentMNC : currentMNC2,
    };

    const deviceParams = {
      curef: settings.get('deviceinfo.cu'),
      platform: settings.get('deviceinfo.os').split(' ')[0],
      /**
       * https://bugzilla.kaiostech.com/show_bug.cgi?id=111463
       * Gary: should always request for bookmark-type apps from search api
       * regardless if the device supports it
       */
      bookmark: true,
    };
    const pageParams = {
      // use 0 for the first page
      page: 0,
      size: PAGE_SIZE,
    };

    return { ...communicationParams, ...deviceParams, ...pageParams };
  }

  /**
   * Combine the device and query params with baseURL and apiName to the complete endpoint.
   * @param {String} params - The prefix of the endpoint you are using.
   * @return {String} - The endpoint URL
   * @private
   */
  _compositeSearchURL(params) {
    const queryString = createQuerystringFromParams(params);
    return `${this._searchBaseUrl}${this._apiVersion}${
      this._apiName
    }${queryString}`;
  }

  /**
   * Search apps by keywords
   * @param {String} keyword - The keyword user want to search, default to
   * empty string.
   * @param {String} querySource - To denote where the query source is.
   * @return {Promise} Apps in the "data" field.
   */
  searchApps(keyword = '', querySource) {
    const queryObject = querySource
      ? { query: keyword, querySource }
      : { query: keyword };
    const locale = navigator.mozL10n.language.code || 'en-US';
    const querystringObj = { ...this._defaultParams, ...queryObject, locale };
    const url = this._compositeSearchURL(querystringObj);
    return fetchThroughClient(url, { useDeviceInfo: true });
  }
}

export default new SearchHelper();
