import 'scss/LinkAppBrick.scss';

import React, { Component } from 'react';

class LinkAppBrick extends Component {
  constructor(props) {
    super(props);
    this.imgRef = null;
    this.state = {
      imgLoaded: false,
    };
  }

  componentDidMount = () => {
    this.imgRef.onload = () => {
      this.setState({ imgLoaded: true });
    };
  };

  onKeydown = e => {
    switch (e.key) {
      case 'Enter':
        this.openLinkApp();
        break;
      default:
        // do nothing, pass to parent.
        break;
    }
  };

  openLinkApp = () => {
    const { app } = this.props;
    if (app.info.url) {
      app.launch();
    }
  };

  // This method is used by AppListView in AppsPanel
  handleFocus = async () => {
    this.brickRef.focus();
    this.brickRef.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  render() {
    const { imgLoaded } = this.state;
    const { app } = this.props;
    const { bg, icon } = app.info;

    return (
      <div
        className="LinkAppBrick"
        data-icon={imgLoaded ? '' : 'file-photo'}
        tabIndex="1"
        onKeyDown={this.onKeydown}
        ref={element => {
          this.brickRef = element;
        }}
      >
        <img
          className="app-icon"
          src={bg || icon}
          alt=""
          ref={element => {
            this.imgRef = element;
          }}
        />
      </div>
    );
  }
}

export default LinkAppBrick;
