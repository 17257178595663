import React, { Component } from 'react';
import { openURL } from '@/utils';

import OptionMenuLib from './OptionMenuLib';
import {
  navigateToSetting,
  callbackLosingWarning,
  navigateToDownloadHistory,
} from './utils';
import { AVAILABLE_OPTION_MENU_ITEM } from './constants';
import 'scss/react_option_menu.scss';

export const DEFAULT_SHARE_APP_SUB_MENU_OPTIONS = [
  AVAILABLE_OPTION_MENU_ITEM.MAIL,
  AVAILABLE_OPTION_MENU_ITEM.MESSAGES,
  AVAILABLE_OPTION_MENU_ITEM.SOCIAL_MEDIA,
];

export const optionsMap = {
  [AVAILABLE_OPTION_MENU_ITEM.PRIVACY_POLICY]: {
    callback: openURL('https://www.kaiostech.com/privacy-policy/'),
    dataicon: 'security',
  },
  [AVAILABLE_OPTION_MENU_ITEM.TERMS_AND_SERVICES]: {
    callback: openURL('https://www.kaiostech.com/kaistore-terms-of-service/'),
    dataicon: 'browser-reader-mode',
  },
  [AVAILABLE_OPTION_MENU_ITEM.NEED_HELP]: {
    callback: openURL('https://support.kaiostech.com/support/tickets/new'),
    dataicon: 'question',
  },
  [AVAILABLE_OPTION_MENU_ITEM.SETTINGS_AND_ACCOUNT]: {
    callback: navigateToSetting,
    dataicon: 'settings',
  },
  [AVAILABLE_OPTION_MENU_ITEM.SETTINGS]: {
    callback: navigateToSetting,
    dataicon: 'settings',
  },
  [AVAILABLE_OPTION_MENU_ITEM.UNINSTALL]: {
    callback: () => callbackLosingWarning(),
    dataicon: 'delete',
  },
  [AVAILABLE_OPTION_MENU_ITEM.SHARE_APP]: {
    callback: () => callbackLosingWarning(),
    subMenuHeader: 'share-with',
    subMenu: DEFAULT_SHARE_APP_SUB_MENU_OPTIONS,
    dataicon: 'share',
  },
  [AVAILABLE_OPTION_MENU_ITEM.SOCIAL_MEDIA]: {
    callback: () => callbackLosingWarning('shareAppCallback'),
    dataicon: '',
  },
  [AVAILABLE_OPTION_MENU_ITEM.MAIL]: {
    callback: () => callbackLosingWarning('shareAppCallback'),
    dataicon: 'email',
  },
  [AVAILABLE_OPTION_MENU_ITEM.MESSAGES]: {
    callback: () => callbackLosingWarning('shareAppCallback'),
    dataicon: 'messages',
  },
  [AVAILABLE_OPTION_MENU_ITEM.PRIVACY_POLICY_DEVELOPER]: {
    callback: () => callbackLosingWarning(),
    dataicon: 'privacy',
  },
  [AVAILABLE_OPTION_MENU_ITEM.CUSTOMER_SUPPORT]: {
    callback: () => callbackLosingWarning(),
    dataicon: 'information',
  },
  [AVAILABLE_OPTION_MENU_ITEM.DEVELOPER_WEBSITE]: {
    callback: () => callbackLosingWarning(),
    dataicon: 'internet-browsing',
  },
  [AVAILABLE_OPTION_MENU_ITEM.DOWNLOAD_HISTORY]: {
    callback: navigateToDownloadHistory,
    dataicon: 'file-download-01',
  },
};

class OptionMenu extends Component {
  constructor(props) {
    super(props);
    this.optionMenuConfig = this.createOptionMenuConfig(
      props.options,
      props.customOptionsCallback
    );
  }

  componentDidUpdate(prevProps) {
    const { isRemovableApp, options, customOptionsCallback } = this.props;

    if (prevProps.isRemovableApp !== isRemovableApp) {
      this.optionMenuConfig = this.createOptionMenuConfig(
        options,
        customOptionsCallback
      );
    }
  }

  formatOptions = (options, customOptionsCallback) => {
    return options.map(id => {
      const {
        callback = null,
        subMenu = [],
        subMenuHeader = 'options',
        dataicon,
      } =
        optionsMap[id] || {};

      return {
        id,
        dataicon,
        callback: customOptionsCallback[id]
          ? customOptionsCallback[id]
          : callback,
        subMenuHeader,
        subMenu:
          subMenu.length > 0 &&
          this.formatOptions(subMenu, customOptionsCallback),
      };
    });
  };

  createOptionMenuConfig(options, customOptionsCallback = {}) {
    const formattedOptions = this.formatOptions(options, customOptionsCallback);

    return { options: formattedOptions };
  }

  render() {
    const { showOptionMenu, toggleOptionMenu } = this.props;

    return showOptionMenu ? (
      <OptionMenuLib
        options={this.optionMenuConfig.options}
        toggleOptionMenu={toggleOptionMenu}
      />
    ) : null;
  }
}

export default OptionMenu;
