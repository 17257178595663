/**
 * This is migrated from Touch shared components with minor modification
 * Credit: https://git.kaiostech.com/touch-apps/shared/-/blob/master/src/components/loader/index.js
 * Author: Amanda CHEN
 */

import React from 'react';

import 'scss/component/LoadingIcon.scss';

const LoadingIcon = () => (
  <div className="loading-container">
    <div className="stick jumping">
      <div className="spinning">
        <div className="upper-circle" />
        <div className="trunk-rect" />
        <div className="lower-circle" />
      </div>
    </div>
    <div className="stick jumping middle">
      <div className="spinning">
        <div className="upper-circle" />
        <div className="trunk-rect" />
        <div className="lower-circle" />
      </div>
    </div>
    <div className="stick jumping">
      <div className="spinning">
        <div className="upper-circle" />
        <div className="trunk-rect" />
        <div className="lower-circle" />
      </div>
    </div>
  </div>
);

export default LoadingIcon;
