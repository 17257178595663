import '../../scss/Marquee.scss';

import React, { Component } from 'react';

class Marquee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titleInlineStyle: {},
    };
    if (document.dir === 'rtl') {
      this.animationName = 'marquee-animation-rtl';
    } else {
      this.animationName = 'marquee-animation';
    }
  }

  componentDidMount() {
    const second = this.overflowTitle.scrollWidth / ((6 / 90) * 1000);
    this.setState({
      titleInlineStyle: {
        animation: `${this.animationName} ${second}s linear 1s`,
      },
    });
    this.element.addEventListener(
      'animationend',
      () => {
        this.setState({
          titleInlineStyle: {
            animation: `none`,
          },
        });
      },
      false
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.titleInlineStyle.animation !== 'none' &&
      this.state.titleInlineStyle.animation === 'none'
    ) {
      const second = this.overflowTitle.scrollWidth / ((6 / 90) * 1000);
      this.setState({
        titleInlineStyle: {
          animation: `${this.animationName} ${second}s linear 3s`,
        },
      });
    }
  }

  content(index) {
    const child = React.Children.only(this.props.children);
    const props = { className: 'marquee_content' };
    if (index === 1) {
      props.ref = element => {
        this.overflowTitle = element;
      };
    }
    return React.cloneElement(child, props);
  }

  render() {
    return (
      <div
        className="marquee"
        style={this.state.titleInlineStyle}
        ref={element => {
          this.element = element;
        }}
      >
        {this.content(0)}
        {this.content(1)}
      </div>
    );
  }
}

export default Marquee;
