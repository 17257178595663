import React from 'react';
import AsyncRoute from 'preact-async-route';
import LoadingIcon from '@/component/LoadingIcon';
import dynamicPanelLoader from './utils';

const AsyncPanel = ({ path, panelName, ...props }) => (
  <AsyncRoute
    path={path}
    getComponent={dynamicPanelLoader(panelName)}
    loading={() => <LoadingIcon />}
    {...props}
  />
);

export default AsyncPanel;
