import React, { Component } from 'react';

import Text from './Text';

export default class OptionMenuLibOptions extends Component {
  render() {
    const { options, optionsRefs, currentFocusIndex } = this.props;
    return options.map((option, index) => {
      const classInfo = `content${option.checked ? ' checked' : ''}`;
      const text = option.id
        ? navigator.mozL10n.get(option.id, option.l10nArgs)
        : option.label;

      return (
        <div
          key={`option-${option.id}`}
          tabIndex="-1"
          data-index={index}
          className="menu-item p-pri"
          ref={optionsRefs[option.id]}
        >
          {option.icon && (
            <img alt={option.id} src={option.icon} className="icon" />
          )}
          <div className={classInfo}>
            <span className="content_icon" data-icon={option.dataicon || ''} />
            <Text
              text={text}
              isActive={option.id === options[currentFocusIndex].id}
            />
          </div>
        </div>
      );
    });
  }
}
