import {
  InstallCommand,
  UninstallCommand,
  UpdateAppCommand,
  LaunchAppCommand,
  CancelDownloadCommand,
  ShowToastCommand,
  CheckIfStorageEnoughCommand,
  PINBookmarkCommand,
  UnPINBookmarkCommand,
} from 'kaistore-post-messenger/src/commands';
import {
  mozAppEvent as constMozAppEvent,
  validStorageSections,
  internalApps,
  preloadedApps,
} from 'kaistore-post-messenger/lib/constants';
import { Toast, Bookmark } from 'kaistore-post-messenger/src/models';
import { MessageSender } from 'web-message-helper';

import { mozAppHelper } from '@/helper/mozapp-helper';
import analyticsHelper from '@/helper/analytics-helper';
import { loggerHelper, bookmarkEvent } from '@/helper/logger-helper';
import paymentHelper from '@/helper/payment-helper';
import {
  isValidURL,
  normalizedURL,
  localeCode,
  trimBgs,
  trimIcons,
  trimLocales,
  getSize,
  getRemoteLocales,
} from '@/helper/application-helper';
import {
  getHigherVersion,
  checkIsUpdatableApp,
  formatRemoteManifestURL,
  openURL,
} from '@/utils';
import { getDefaultShareAppMenuOptions } from '@/component/OptionMenu/utils';
import { deviceUtils } from '@/device-utils';

import { SUPPORTED_APP_TYPE } from '@/constant';
import AppStore from '@/app-store';

class Application {
  constructor(manifestURL) {
    this.manifestURL = formatRemoteManifestURL(manifestURL);
    this._remoteInfo = null;
    this._mozAPP = null;
    this.state = {
      downloading: false,
      updatable: false,
      liked: false,
      pinned: false,
      updatePending: false,
      coreUpdated: false,
    };
    this._trimmedRemoteInfo = {};
    this._trimmedMozAPPInfo = {};
    this.name = null;
    this.coreManualUpdate = false;
    this.isPurchased = false;
    this.purchasedDate = 0;
  }

  get isLink() {
    return this.info.type === 'link';
  }

  get isBookmark() {
    return this.info.type === 'bookmark';
  }

  get isHosted() {
    return this.info.type === 'hosted';
  }

  get isInternal() {
    return Object.values(internalApps).includes(this.id);
  }

  get isPreloaded() {
    return Object.values(preloadedApps).includes(this.id);
  }

  get core() {
    return Boolean(
      this.mozAPP && this.mozAPP.manifest && this.mozAPP.manifest.core
    );
  }

  get isCoreUpdating() {
    return this.core && this.state.updatable && this.state.downloading;
  }

  get isRebootNeeded() {
    return Boolean(
      this.mozAPP && this.mozAPP.manifest && this.mozAPP.manifest.reboot
    );
  }

  get id() {
    if (this.remoteInfo && this.remoteInfo.id) {
      return this.remoteInfo.id;
    }

    const manifestInfo = this.manifestURL.split('/');
    const lastPart = manifestInfo[manifestInfo.length - 1];
    if (lastPart === 'manifest.webapp') {
      return null;
    }
    return manifestInfo[manifestInfo.length - 1];
  }

  get remoteInfo() {
    return this._remoteInfo;
  }

  get mozAPP() {
    return this._mozAPP;
  }

  get isInRemoteList() {
    return this._remoteInfo !== null;
  }

  // Both _trimmedRemoteInfo and _trimmedMozAPPInfo are empty object.
  get isUnsetTrimmedInfo() {
    return (
      Object.keys(this._trimmedRemoteInfo).length === 0 &&
      Object.keys(this._trimmedMozAPPInfo).length === 0
    );
  }

  get isInstalled() {
    return Boolean(this._mozAPP && this._mozAPP.installState === 'installed');
  }

  get isRemovable() {
    return Boolean(
      this._mozAPP &&
        this._mozAPP.removable &&
        this._mozAPP.installState === 'installed'
    );
  }

  get info() {
    const remoteInfo = this._trimmedRemoteInfo;
    const mozAPPInfo = this._trimmedMozAPPInfo;
    let version = null;
    /**
     * In order to show the newest version available for the app even when
     * remote info is fetched from cache, we show the newest version between
     * remote info and updateManifest
     */
    if (remoteInfo.version && mozAPPInfo.version) {
      version = getHigherVersion(remoteInfo.version, mozAPPInfo.version);
    } else {
      version = remoteInfo.version || mozAPPInfo.version;
    }
    return {
      isYmalAd: remoteInfo.isYmalAd || false,
      isSearchAd: remoteInfo.isSearchAd || false,
      bg: remoteInfo.bg || mozAPPInfo.bg || null,
      icon: remoteInfo.icon || mozAPPInfo.icon || null,
      localized: remoteInfo.localized || mozAPPInfo.localized || {},
      url: remoteInfo.url || null,
      type: remoteInfo.type || mozAPPInfo.type,
      paid: remoteInfo.paid || false,
      productId: remoteInfo.productId || null,
      version,
      size: remoteInfo.size || mozAPPInfo.size || null,
      developer: remoteInfo.developer,
      ymal: this.ymal,
      screenshots: remoteInfo.screenshots,
      ...(remoteInfo.paid && { unitPrice: remoteInfo.unitPrice }),
      categoryList: remoteInfo.categoryList || [],
    };
  }

  get brickInfo() {
    return {
      id: this.id,
      isBookmark: this.isBookmark,
      state: this.state,
      isInstalled: this.isInstalled,
      isPurchased: this.isPurchased,
      info: this.info,
    };
  }

  get isOptionMenuAvailable() {
    const options = getDefaultShareAppMenuOptions(this.isRemovable);
    return options.length > 0;
  }

  get softKeyOption() {
    /**
     * "action":  Referring to what action should be performed when the softkey
     *            is being pressed. Please see handleSoftKeyAction function from
     *            src/panel/AppDetailPanel/AppDetailPanel.js for more detail.
     * "display": Referring to what should be displayed to users. Text would be
     *            shown directly by setting string for this field. Icon would
     *            be shown instead if the field is set to {icon: '[icon-name]'}.
     */
    let option = {
      action: {
        left: null,
        center: null,
        right: null,
      },
      display: {
        left: '',
        center: '',
        right: '',
      },
    };
    if (this.isBookmark) {
      const { pinned } = this.state;
      option.action.center = 'open';
      option.display.center = 'open';
      if (deviceUtils.isBookmarkDBSupported) {
        option.action.left = pinned ? 'unpin' : 'pin';
        option.display.left = {
          icon: pinned ? 'pin-off' : 'pin',
        };
      }
      return option;
    }

    const { isInstalled, info, state, isPurchased, mozAPP, core } = this;
    const { paid } = info;
    const {
      updatable,
      downloading,
      // liked,
      coreUpdated,
    } = state;

    if (coreUpdated) {
      option.action.center = 'done';
      option.display.center = 'done';
    } else if (downloading && updatable) {
      option.action.center = null;
      option.display.center = '';
    } else if (downloading && !updatable) {
      option.action.center = 'stop';
      option.display.center = 'stop';
    } else if (paid && !isInstalled && !isPurchased) {
      option.action.center = 'buy';
      option.display.center = 'buy';
    } else if (isInstalled && !updatable) {
      option.action.center = 'open';
      option.display.center = 'open';
    } else if (isInstalled && updatable) {
      option.action.center = 'update';
      option.display.center = 'update';
    } else if (!this.isCoreUpdating) {
      option.action.center = 'get';
      option.display.center = 'get';
    }

    if (isInstalled && !core) {
      // option['left'] = liked ? 'unlike' : 'like'; // enable it when the backend is ready.
    }

    if (
      this.isOptionMenuAvailable &&
      !this.isCoreUpdating &&
      !this.state.coreUpdated
    ) {
      option.action.right = 'options';
      option.display.right = {
        icon: 'more',
      };
    }

    return option;
  }

  set remoteInfo(info) {
    this._remoteInfo = info;
    this._trimmedRemoteInfo = this.trimRemoteInfo(info);
    this.name = info.name;
  }

  set mozAPP(mozAPP) {
    this._mozAPP = mozAPP;
    if (mozAPP && mozAPP.updateManifest) {
      this._trimmedMozAPPInfo = this.trimMozAPPInfo(mozAPP.updateManifest);
    } else {
      this._trimmedMozAPPInfo = {};
    }
  }

  setRemoteInfo(info) {
    const remoteInfo = { ...info };
    if (!SUPPORTED_APP_TYPE.includes(remoteInfo.type)) {
      return false;
    }

    /**
     * Use original manifest_url as fallback if url is missing for bookmark.
     * Not to use the formatted one in order to ensure url of the bookmark
     * is exactly the same as the one fetched from API.
     */
    if (remoteInfo.type === 'bookmark' && !remoteInfo.url) {
      remoteInfo.url = remoteInfo.manifest_url;
    }

    if (this.isRemoteInfoValid(remoteInfo)) {
      if (remoteInfo.ymal) {
        this.rawYmal = remoteInfo.ymal;
      }
      this.remoteInfo = { ...(this.remoteInfo || {}), ...remoteInfo };
      return true;
    }

    return false;
  }

  setRemoteInfoBySearchAPI = appInfo => {
    // translate format for Application model
    const translatedApp = {
      ad: appInfo.ad,
      id: appInfo.id,
      version: appInfo.version,
      manifest_url: appInfo.manifest_url,
      icons: {},
      bgs: {},
      theme: appInfo.theme,
      type: appInfo.type,
      paid: appInfo.paid,
      created_at: appInfo.release_date * 1000000,
      display: appInfo.name,
      subtitle: appInfo.summary,
      description: appInfo.description,
      screenshots: appInfo.screenshots,
      product_id: appInfo.product_id || null,
      category_list: appInfo.category_list || [],
    };

    if (appInfo.thumbnail_url) {
      translatedApp.icons = {
        '56': appInfo.thumbnail_url,
      };
    }

    if (appInfo.background_url) {
      translatedApp.bgs = {
        '168': appInfo.background_url,
      };
    }

    return this.setRemoteInfo(translatedApp);
  };

  isRemoteInfoValid(remoteInfo) {
    if (remoteInfo.type === 'bookmark') {
      const URL = remoteInfo.url;
      return URL && isValidURL(URL);
    }
    return remoteInfo.id && remoteInfo.manifest_url;
  }

  trimRemoteInfo(remoteInfo) {
    const formatUnitPrice = (currency, price) => {
      if (currency && price) {
        return `${currency} $${price}`;
      }
      if (price) {
        return `$${price}`;
      }
      return null;
    };

    const localized = getRemoteLocales(remoteInfo);

    return {
      // 'is_sponsored' is used in graphql api; 'ad' is used in search api
      isSearchAd: remoteInfo.ad || false,
      isYmalAd: remoteInfo.is_sponsored || false,
      bg: trimBgs(remoteInfo.bgs),
      icon: trimIcons(remoteInfo.icons),
      localized:
        // We shouldn't override localized object if latest one got empty object.
        Object.keys(localized).length > 0
          ? localized
          : this._trimmedRemoteInfo.localized,
      url: remoteInfo.url ? normalizedURL(remoteInfo.url) : null,
      type: remoteInfo.type,
      paid: remoteInfo.paid || false,
      productId: remoteInfo.product_id || null,
      version: remoteInfo.version || null,
      size: remoteInfo.packaged_size ? getSize(remoteInfo.packaged_size) : null,
      developer: remoteInfo.developer || null,
      screenshots: remoteInfo.screenshots || {},
      ...(remoteInfo.paid && {
        unitPrice: formatUnitPrice(remoteInfo.currency, remoteInfo.price),
      }),
      categoryList: remoteInfo.category_list || [],
    };
  }

  trimMozAPPInfo(updateManifest) {
    return {
      bg: trimBgs(updateManifest.bgs),
      icon: trimIcons(updateManifest.icons),
      localized: trimLocales(
        updateManifest.locales,
        localeCode(updateManifest)
      ),
      type: updateManifest.type,
      version: updateManifest.version || null,
      size: getSize(updateManifest.packaged_size),
    };
  }

  updateState(detail) {
    this.state = { ...this.state, ...detail };
    // auto flag updatePending to false when app starts downloading
    if (detail.downloading === true) {
      this.state = { ...this.state, updatePending: false };
    }
    const evt = new CustomEvent('appstore:change', {
      detail: { manifestURL: this.manifestURL },
    });
    window.dispatchEvent(evt);
  }

  purchase() {
    const {
      productId,
      localized: { name },
    } = this.info;

    return (
      paymentHelper
        .isProductPurchased(productId)
        .then(product => {
          const transactionId = product.transaction_id;
          return paymentHelper.getReceiptsByTransactionId(transactionId);
        })
        // app has been purchased
        .then(result => {
          const jwt = {
            receiptToken: result.receipt_token,
          };
          return jwt;
        })
        // app has not been purchased, so pay to get jwt
        .catch(() => {
          return paymentHelper
            .pay({
              id: productId,
              name,
            })
            .then(jwt => {
              if (!jwt) {
                throw new Error('Failed to get jwt when pay');
              }
              return jwt;
            });
        })
        .then(jwt => {
          this.isPurchased = true;
          // TODO: might need to emit appstore:change, but currently piggyback on install()
          return jwt;
        })
        .catch(error => {
          // must throw error here or it would still trigger purchase().then
          throw new Error(error);
        })
    );
  }

  install(jwt = null, skipChecking = false) {
    return new Promise((resolve, reject) => {
      const checkStorageCommend = new CheckIfStorageEnoughCommand({
        detail: { section: validStorageSections.INSTALL },
      });

      const requestInstall = () => {
        this.updateState({ downloading: true });
        console.warn('[Store Remote]: Send installCommand');
        const installCommand = new InstallCommand({
          detail: {
            isHosted: this.isHosted,
            manifestURL: this.manifestURL,
            jwt,
          },
        });

        MessageSender.send(installCommand, (success, detail) => {
          if (success === true) {
            resolve();
          } else {
            const errorName = detail.error && detail.error.error;
            this.sendInstallData('store_app_install_failed', {
              name: errorName,
              message: '',
            });
            reject(detail.error);
          }
        });
      };

      if (skipChecking) {
        requestInstall();
      } else {
        MessageSender.send(checkStorageCommend, isEnough => {
          if (!isEnough) {
            return;
          }
          requestInstall();
        });
      }
    });
  }

  uninstall() {
    const command = new UninstallCommand({
      detail: {
        manifestURL: this.manifestURL,
      },
    });
    this.sendInstallData('store_app_uninstall_init');
    MessageSender.send(command);
  }

  update(skipChecking = false) {
    return new Promise((resolve, reject) => {
      const requestUpdate = () => {
        this.sendInstallData('store_app_update_init');
        this.updateState({ downloading: true });
        if (this.core) {
          this.coreManualUpdate = true;
        }

        const command = new UpdateAppCommand({
          detail: {
            manifestURL: this.manifestURL,
          },
        });
        MessageSender.send(command, success => {
          if (success) {
            resolve();
          } else {
            reject();
          }
        });
      };

      if (skipChecking) {
        requestUpdate();
      } else {
        MessageSender.send(
          new CheckIfStorageEnoughCommand({
            detail: { section: validStorageSections.UPDATE },
          }),
          isEnough => {
            if (!isEnough) {
              reject(new Error('Not enough space'));
              return;
            }
            requestUpdate();
          }
        );
      }
    });
  }

  pin = () => {
    if (!this.isBookmark) {
      console.error('Not a bookmark app, pin() should not be called');
      return;
    }
    const {
      icon,
      url,
      localized: { name },
    } = this.info;
    const command = new PINBookmarkCommand({
      detail: new Bookmark({ url, icon, name }),
    });
    MessageSender.send(command, success => {
      if (success === true) {
        loggerHelper.postBookmarkData(bookmarkEvent.PIN, this.id, this.info);
      }
    });
  };

  unPin = () => {
    if (!this.isBookmark) {
      console.error('Not a bookmark app, unPin() should not be called');
      return;
    }
    const { url } = this.info;
    const command = new UnPINBookmarkCommand({ detail: { url } });
    MessageSender.send(command);
  };

  launch() {
    if (this.isLink) {
      const { url } = this.info;
      openURL(url)();
    } else if (this.isBookmark) {
      const { url, manifestURL } = this.info;
      const bookmarkURL = url || manifestURL;
      loggerHelper.postBookmarkData(bookmarkEvent.OPEN, this.id, this.info);
      openURL(bookmarkURL)();
    } else {
      const command = new LaunchAppCommand({
        detail: {
          manifestURL: this.manifestURL,
        },
      });
      MessageSender.send(command);
    }
  }

  cancelDownload() {
    const command = new CancelDownloadCommand({
      detail: {
        manifestURL: this.manifestURL,
      },
    });
    if (this.state.updatable) {
      this.sendInstallData('store_app_update_cancel_init');
    } else {
      this.sendInstallData('store_app_install_cancel_init');
    }
    MessageSender.send(command);
  }

  handleDownload() {
    this.updateState({
      downloading: true,
    });
  }

  handleDownloadError() {
    this.updateState({
      downloading: false,
    });
  }

  handleDownloadSuccess() {
    // Save downloaded app record into asyncStorage
    if (!this.isInternal && !this.isPreloaded) {
      const downloadedDate = Date.now() * 1000000; // * 1000000 to match "created_date" from paymentHelper.getAllPurchased()
      AppStore.addDownloadAppRecord({
        manifestURL: this.manifestURL,
        downloadedDate,
        ...this.brickInfo,
      });
    }
    // Remove unused info: description & locales.description
    if (this.mozAPP) {
      mozAppHelper.releaseUnusedInfo(this.mozAPP);
    }
    this.updateState({
      downloading: false,
      updatable: false,
    });
  }

  handleUpdatedSuccess() {
    const isCoreAppUpdatedManually = this.core && this.coreManualUpdate;
    this.updateState({
      downloading: false,
      updatable: false,
      // PagePanel uses this value to show Done button, which shows only when
      // a core app is updated manually
      coreUpdated: isCoreAppUpdatedManually,
    });
  }

  handleInstall(mozAppEvent) {
    if (this.isInstalled) {
      this.handleDownloadSuccess();
      this.sendInstallData('store_app_install_done');
      return;
    }

    if (mozAppEvent === constMozAppEvent.ON_DOWNLOAD_APPLIED) {
      this.handleDownloadSuccess();
      this.sendInstallData('store_app_install_done');
    } else if (mozAppEvent === constMozAppEvent.ON_DOWNLOAD_ERROR) {
      const downloadError = this.mozAPP && this.mozAPP.downloadError;
      this.handleDownloadError();
      this.sendInstallData('store_app_install_failed', downloadError);
    } else {
      this.handleDownload();
    }
  }

  handleUninstall() {
    // clear up app props.
    const appName = this.info.localized.name;
    this.mozAPP = null;

    const isEmptyApplication = Object.keys(this.info.localized).length === 0;
    if (isEmptyApplication) {
      // FIXME: dependency cycle
      AppStore.clearEmptyApplication(this.manifestURL);
    }

    this.updateState({
      downloading: false,
      updatable: false,
    });

    const messageOption = {
      messageL10nId: 'uninstalled-msg-with-name',
      messageL10nArgs: {
        appName,
      },
    };

    MessageSender.send(
      new ShowToastCommand({
        detail: new Toast(messageOption),
      })
    );
  }

  handleUpdate(mozAppEvent) {
    switch (mozAppEvent) {
      case constMozAppEvent.ON_DOWNLOAD_AVAILABLE:
        if (checkIsUpdatableApp(this)) {
          this.updateState({ updatable: true });
        }
        break;
      case constMozAppEvent.ON_DOWNLOAD_APPLIED:
        this.handleUpdatedSuccess();
        this.sendInstallData('store_app_update_done');
        break;
      case constMozAppEvent.ON_DOWNLOAD_SUCCESS:
        this.handleDownloadSuccess();
        break;
      case constMozAppEvent.ON_DOWNLOAD_ERROR:
        this.handleDownloadError();
        break;
      default:
        console.error(
          `handleUpdate is not able to handle mozAppEvent: ${mozAppEvent}`
        );
        break;
    }
  }

  handleBookmarkUpdate(pin, silent) {
    this.updateState({ pinned: pin });

    if (!silent) {
      const messageL10nId = pin
        ? 'pin-bookmark-completely'
        : 'unpin-bookmark-completely';
      MessageSender.send(
        new ShowToastCommand({
          detail: new Toast({ messageL10nId }),
        })
      );
    }
  }

  sendInstallData(event_name = '', error_detail = null) {
    const logInfo = {
      event_name,
      app_id: this.id,
      app_version: this.info.version,
    };

    if (error_detail) {
      logInfo.error_detail = error_detail;
    }

    analyticsHelper.postAppActionEvent(logInfo);
  }
}

export default Application;
