export const loadImage = (element, type = 'icon') => {
  let imageSrc = '';
  let image = null;

  if (type === 'icon') {
    image = element.querySelector('img.app-icon');
    imageSrc = image.getAttribute('data-src');
  } else if (type === 'bg') {
    image = new Image();
    imageSrc = element.getAttribute('data-bg-image');
    if (imageSrc === null) {
      return;
    }
  }

  return new Promise((resolve, reject) => {
    image.onload = function() {
      if (type === 'bg') {
        element.style.backgroundImage = 'url(' + image.src + ')';
        element.classList.add('bg-loaded');
      } else if (type === 'icon') {
        element.querySelector('.icon-wrapper').classList.remove('default');
      }
      resolve();
    };
    image.onerror = function() {
      resolve();
    };
    image.src = imageSrc;
  });
};
