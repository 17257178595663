import SoftKeyStore from 'soft-key-store';
import { deviceUtils } from '@/device-utils';

const dump = (...args) => {
  console.warn(...args);
};

const performanceMeasure = name => {
  if (name === 'REMOTE_STORE') {
    performance.measure(name, name, name);
  } else {
    const start = performance.getEntriesByName(`${name}_START`);
    const end = performance.getEntriesByName(`${name}_END`);

    if (start.length && end.length) {
      performance.measure(name, `${name}_START`, `${name}_END`);
    } else {
      dump(`[Measure Failed] ${name}`);
    }
  }
};

const findEntity = str => {
  const resources = performance.getEntriesByType('resource');
  return resources.find(element => element.name.indexOf(str) > 0);
};

export const performanceLogs = timestamp => {
  const measures = [
    'REMOTE_STORE',
    'REQUEST_DEVICE',
    'REQUEST_TOKEN',
    'FETCH_APPS_CAT',
    'REQUEST_INSTALLED',
    'REMOTE_PAGE_RENDERING',
  ];
  let dataArray = null;
  let loadApps = null;
  let loadCategories = null;
  let loadSearchApp = null;
  let startTime = 0;
  let timing = 0;
  let duration = 0;

  measures.forEach(key => performanceMeasure(key));
  dataArray = performance.getEntriesByType('measure');

  loadApps = findEntity('/apps?');
  loadApps && dataArray.push(loadApps);
  loadCategories = findEntity('/categories?');
  loadCategories && dataArray.push(loadCategories);
  loadSearchApp = findEntity('/graphql');
  loadSearchApp && dataArray.push(loadSearchApp);

  // print the logs
  dump('******** [Remote] Performance Testing ********');
  const timeOriginInEpoch = Math.round(Date.now() - performance.now());
  dump(`Remote's timeOrigin: ${timeOriginInEpoch}`);

  dataArray.sort((a, b) => a.startTime - b.startTime).forEach((data, index) => {
    if (index === 0) {
      dump(
        `[First Performance Mark][${data.name}] delta with timeOrigin: ${
          data.startTime
        }`
      );
      startTime = data.startTime;
    }
    timing = (data.startTime - startTime).toFixed(2);
    duration = data.duration.toFixed(2);
    dump(
      `[${data.name.split('?')[0]}] start: ${timing} ms, duration: ${duration}`
    );
  });

  dump('First view is ready at', timestamp);
  const perfTiming = performance.timing;
  const pageLoadTime = perfTiming.loadEventEnd - perfTiming.navigationStart;
  const renderTime = perfTiming.domComplete - perfTiming.domLoading;
  dump(
    `[Remote pageLoadTime: ${pageLoadTime}] loadEventEnd: ${
      perfTiming.loadEventEnd
    }, navigationStart: ${perfTiming.navigationStart}, `
  );
  dump(
    `[Remote renderTime: ${renderTime}] domComplete: ${
      perfTiming.domComplete
    }, domLoading: ${perfTiming.domLoading}`
  );
  dump('******** [Remote] End ********');
};

export const isVersionHigher = (oldVersion, newVersion) => {
  let oldVerDigits = oldVersion.split('.').map(digit => parseInt(digit, 10));
  let newVerDigits = newVersion.split('.').map(digit => parseInt(digit, 10));

  // filling 0 if number of the digits are not the same
  const missingDigit = Math.abs(oldVerDigits.length - newVerDigits.length);
  if (missingDigit > 0) {
    if (oldVerDigits.length > newVerDigits.length) {
      newVerDigits = newVerDigits.concat(Array(missingDigit).fill(0));
    } else {
      oldVerDigits = oldVerDigits.concat(Array(missingDigit).fill(0));
    }
  }

  for (let i = 0; i < oldVerDigits.length; i++) {
    const oldDigit = oldVerDigits[i];
    const newDigit = newVerDigits[i];
    if (newDigit > oldDigit) {
      return true;
    }
    if (oldDigit > newDigit) {
      return false;
    }
  }
  return false;
};

export const getHigherVersion = (oldVersion, newVersion) => {
  if (isVersionHigher(oldVersion, newVersion)) {
    return newVersion;
  }
  return oldVersion;
};

export const clamp = (number, min, max) => {
  if (min > max) {
    throw new Error('The min value cannot be larger than max value');
  }
  return Math.min(Math.max(number, min), max);
};

export const getQuerystring = url => {
  const firstQuestionIndex = url.indexOf('?');
  if (firstQuestionIndex === -1) return '';
  let decodedQuerystring = url.substring(firstQuestionIndex + 1);
  // some part of querystring might be encoded. decode it back
  while (decodedQuerystring !== decodeURIComponent(decodedQuerystring)) {
    decodedQuerystring = decodeURIComponent(decodedQuerystring);
  }
  return decodedQuerystring;
};

export const formatRemoteManifestURL = manifestURL => {
  const isEncoded = decodeURI(manifestURL) !== manifestURL;
  if (isEncoded) {
    return manifestURL;
  }
  // trim manifest url to make sure there is no space in it.
  return encodeURI(manifestURL.trim());
};

export const checkIsUpdatableApp = application => {
  const { mozAPP } = application;
  /**
   * If the application is still downloading or being cancelled during
   * downloading, there would be no manifest field
   */
  const hasBothManifest = mozAPP.manifest && mozAPP.updateManifest;
  if (
    !hasBothManifest ||
    !isVersionHigher(mozAPP.manifest.version, mozAPP.updateManifest.version) ||
    application.isInternal ||
    application.isPreloadedApp ||
    !application.isInstalled
  ) {
    return false;
  }
  return true;
};

export const getManifestURLById = id => {
  const baseURL = deviceUtils.settings.get('identity.kaiaccounts.api.uri');
  return `${baseURL}/apps/manifest/${id}`;
};

export const getOSVersion = () => {
  const regexForGetOSVersion = /\b(B2GOS|KaiOS)+\/(\d)/i;
  /**
   * Should get result array like: ["B2GOS/3", "B2GOS", "3"], ["KaiOS/3", "KaiOS", "3"]
   * 3: Gecko ^85 store, 2: Gecko 48 store
   */
  const getOSVersionResult = regexForGetOSVersion.exec(navigator.userAgent);
  const OSVersion =
    getOSVersionResult && getOSVersionResult[2]
      ? Number(getOSVersionResult[2])
      : 2;

  return OSVersion;
};

export const isNextOS = () => {
  const OSVersion = getOSVersion();
  return OSVersion > 2;
};

export const openURL = url => () => {
  if (isNextOS()) {
    window.open(url, '_blank');
  } else {
    new MozActivity({ name: 'view', data: { type: 'url', url } });
  }
};

export const createQuerystringFromParams = params => {
  const queries = Object.entries(params).reduce(
    (acc, [key, value]) => acc.concat(`&${key}=${value}`),
    ''
  );
  return queries.replace('&', '?');
};

export const registerSoftKey = (
  options,
  element,
  theme = 'floating-button-theme'
) => {
  SoftKeyStore.register({ ...options, theme }, element);
};
