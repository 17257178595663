export const APP_ORIGIN = 'app://kaios-plus.kaiostech.com';
export const APP_HTTP_ORIGIN = 'http://kaios-store.localhost';

export const PATH = {
  LOADING: {
    MATCH: '/loading',
    URL: () => {
      return '/loading';
    },
  },
  WARNING: {
    MATCH: '/warning',
    URL: () => {
      return '/warning';
    },
  },
  APPS: {
    MATCH: '/',
    URL: ({ categoryCode = null } = {}) => {
      const params = new URLSearchParams();
      categoryCode && params.append('categoryCode', categoryCode);
      return `/?${params.toString()}`;
    },
  },
  SEARCH: {
    MATCH: '/search',
    URL: () => {
      return '/search';
    },
  },
  SETTING: {
    MATCH: '/setting',
    URL: () => {
      return '/setting';
    },
  },
  DOWNLOAD_HISTORY: {
    MATCH: '/download_history',
    URL: () => {
      return '/download_history';
    },
  },
  PAGE: {
    MATCH: '/app/:id?',
    URL: ({
      id = '',
      adId = null,
      appOrder = null,
      autoDownload = false,
      disposition = 'window',
      manifest = null,
      batchUpdating = false,
      entry = 'apps',
    }) => {
      const params = new URLSearchParams();
      const timestamp = new Date().getTime();

      params.append('t', timestamp);
      adId && params.append('adId', adId);
      typeof appOrder === 'number' && params.append('appOrder', appOrder);
      autoDownload && params.append('autoDownload', autoDownload);
      disposition && params.append('disposition', disposition);
      manifest && params.append('manifest', encodeURIComponent(manifest));
      batchUpdating && params.append('batchUpdating', batchUpdating);
      entry && params.append('entry', entry);

      return `/app/${id}?${params.toString()}`;
    },
  },
  CORE_PAGE: {
    MATCH: '/app/core/:id?',
    URL: ({
      id = '',
      adId = null,
      appOrder = null,
      autoDownload = false,
      disposition = 'window',
      manifest = null,
      batchUpdating = false,
      entry = 'apps',
    }) => {
      const params = new URLSearchParams();
      const timestamp = new Date().getTime();

      params.append('t', timestamp);
      adId && params.append('adId', adId);
      typeof appOrder === 'number' && params.append('appOrder', appOrder);
      autoDownload && params.append('autoDownload', autoDownload);
      disposition && params.append('disposition', disposition);
      manifest && params.append('manifest', encodeURIComponent(manifest));
      batchUpdating && params.append('batchUpdating', batchUpdating);
      entry && params.append('entry', entry);

      return `/app/core/${id}?${params.toString()}`;
    },
  },
};

export const SUPPORTED_APP_TYPE = [
  'bookmark',
  'privileged',
  'hosted',
  'certified',
  'web',
  'link',
];

export const ASYNC_STORAGE_KEYS = {
  USER_BROWSED_HISTORY: 'userBrowsedHistory',
};

export const KAIOS_HOMEPAGE = 'https://www.kaiostech.com/';

// special category code
export const SPECIAL_CATE_CODE = {
  ALL: 'all-apps',
  RECOMMENDED: 'recommended',
};

export const APPBRICK_LOCATION = {
  DETAIL: 'detail',
  YMAL: 'ymal',
  SEARCH: 'search',
  APPLIST: 'applist',
};

/**
 * When the client version is lower than or equal to the lowest requirement,
 * remote would trigger store force update directly.
 **/
export const CLIENT_LOWEST_REQUIREMENT = {
  'KAIOS_2.5': '3.0.0',
  'KAIOS_3.0': '3.2.0',
};
