import '../../../scss/component/UpdateButton.scss';

import React, { Component } from 'react';

import { registerSoftKey } from '@/utils';

const UpdateButton = React.forwardRef(function UpdateButton(props, ref) {
  const { l10nId, onPress } = props;
  const onKeyDown = evt => {
    if (evt.key === 'Enter' && onPress) {
      onPress(evt);
    }
  };
  return (
    <div
      className="update-btn focusable"
      tabIndex="1"
      onKeyDown={onKeyDown}
      ref={ref}
    >
      <div data-l10n-id={l10nId} />
    </div>
  );
});

export default UpdateButton;
