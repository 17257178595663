// TODO: rename it from UserInterfaceHelper to ViewsHelper for brevity
import { MessageSender } from 'web-message-helper';
import {
  ChangeThemeColorCommand,
  RemoteUIReadyCommand,
  ShowNotificationCommand,
} from 'kaistore-post-messenger/src/commands';
import { Notification } from 'kaistore-post-messenger/src/models';

import { performanceLogs } from '@/utils';

/**
 * HTML DOM
 * @typedef {DOM} DOM
 */

/** A class to handle user inteface related operations. */
class UserInterfaceHelper {
  /**
   * Initializes variables.
   */
  constructor() {
    // For tracing the performance while developing.
    this.debug = false;

    // The viewing history of every category.
    // Used for getting back to the last viewed app when returning to the category
    // key: cateCode, val: appOrder
    this.cateHistory = {};

    /**
     * Defines if tiny store has notified client about UI is ready.
     * @type Boolean
     * @public
     */
    this.UIReadyCommandSent = false;
    this.UIState = {
      dialog: false,
      optionMenu: false,
    };
    // For KaiOS 3.0, to determine if elements can do focus() or not.
    // When client-side's remote iframe got/lose focus, the value will be
    // changed by postMessage "IframeHasFocusCommand".
    this.UIHasFocus = false;
    // For KaiOS 3.0 only, recording the latest focus element, and focus it
    // when "UIHasFocus" change to true.
    this.focusElement = null;
  }

  get popupVisible() {
    return Object.values(this.UIState).some(item => item);
  }

  /**
   * Notify client about remote UI is ready.
   * @type {Function}
   * @public
   */
  notifyUIReady(isInlineActivity = false) {
    if (this.UIReadyCommandSent) {
      return Promise.resolve({ hasSentBefore: true });
    }
    return new Promise(resolve => {
      const timestamp = new Date().getTime();
      performance.mark('REMOTE_PAGE_RENDERING_END');
      /**
       * Inform client store that remote UI is ready, and shift page from
       * loading page to remote iframe.
       */
      MessageSender.send(
        new RemoteUIReadyCommand({
          detail: {
            timestamp,
            isInlineActivity,
          },
        }),
        () => {
          this.UIReadyCommandSent = true;
          if (this.debug) {
            performanceLogs(timestamp);
          }
          resolve({ hasSentBefore: false });
        }
      );
    });
  }
}

export default (UserInterfaceHelper = new UserInterfaceHelper());

/**
 * Checks if the element is overflown.
 * @method isOverflown
 * @param {DOM} element - HTML DOM element
 * @return {Boolean}
 */
export const isOverflown = element => {
  if (!element) {
    return false;
  }
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
};

/**
 * Request Store client to change theme color.
 * @method setThemeColor
 * @param {string} [theme=default] - 'setting', 'lightbox', 'default',
 */
export const setThemeColor = (theme = 'default') => {
  const themeList = {
    setting: 'rgb(50, 3, 116)',
    lightbox: 'rgb(0, 0, 0)',
    default: 'rgb(233, 233, 242)',
  };

  const detail = {
    content: themeList[theme] || themeList.default,
  };

  MessageSender.send(new ChangeThemeColorCommand({ detail }));
};

/**
 * TODO: Could extract all notification related functions to new helper if
 * we need to show varied notifications in the future. But for now we only got
 * one so put it here first.
 */
export const showNotification = (titleL10nId, options) => {
  const title = navigator.mozL10n.get(titleL10nId);
  const command = new ShowNotificationCommand({
    detail: new Notification({
      title,
      options,
    }),
  });
  MessageSender.send(command);
};

/**
 * scrollIntoView() only works vertically so we need to calculate by ourselves
 * for scroll to horizontal center.
 */
export const scrollToHorizontalCenter = (
  targetDOM,
  containerDOM,
  smoothScrolling = false
) => {
  const { scrollLeft, offsetWidth } = containerDOM;
  const { left, width } = targetDOM.getBoundingClientRect();
  containerDOM.scrollTo({
    left: scrollLeft + left - offsetWidth / 2 + width / 2,
    behavior: smoothScrolling ? 'smooth' : 'auto',
  });
};
