import React from 'react';
import APP_TYPES from '../constant';

const generateContentObj = data => {
  if (data === 'hide') return {};
  // NOTE: we show 'pin' no matter if bookmark is supported or not (bug-110841)
  const contentObj = { text: '' };
  const useIcon = ['tick', 'download', 'cancel'];
  const useL10n = ['pin', 'update', 'get', 'price', 'free'];
  const _ = navigator.mozL10n.get;

  if (useIcon.includes(data)) {
    contentObj['data-icon'] = data;
    contentObj['data-status'] = data;
  } else if (useL10n.includes(data)) {
    contentObj.text = _(data);
    contentObj['data-status'] = data;
  } else {
    contentObj.text = data;
    contentObj['data-status'] = 'price';
  }

  return contentObj;
};

const getInstalledContent = isUpdatable => (isUpdatable ? 'update' : 'tick');

const getPaymentInfoContent = (isAd, isUsedInDetail, unitPrice) => {
  if (isAd && !isUsedInDetail) {
    if (unitPrice) {
      return 'hide';
    }
    return 'cancel';
  }
  return unitPrice || 'cancel';
};
const StatusTag = ({
  appType = APP_TYPES.FREE_APP,
  unitPrice = null,
  isPinned = false,
  isInstalled = false,
  isUpdatable = false,
  isDownloading = false,
  isPurchased = false,
  isAd = false,
  isUsedInDetail = false,
}) => {
  let content;

  switch (appType) {
    case APP_TYPES.PAID_APP:
      if (isDownloading) {
        content = 'download';
      } else if (isInstalled) {
        content = getInstalledContent(isUpdatable);
      } else if (isPurchased) {
        content = 'get';
      } else {
        content = getPaymentInfoContent(isAd, isUsedInDetail, unitPrice);
      }
      break;
    case APP_TYPES.FREE_APP:
      if (isDownloading) {
        content = 'download';
      } else if (isInstalled) {
        content = getInstalledContent(isUpdatable);
      } else if (isPurchased) {
        content = 'get';
      } else {
        content = 'free';
      }
      break;
    case APP_TYPES.BOOKMARK:
      if (isPinned) {
        content = 'tick';
      } else {
        content = 'pin';
      }
      break;
    default:
      content = 'hide';
      break;
  }

  const contentObj = generateContentObj(content);

  return (
    Object.keys(contentObj).length > 0 && (
      <div className="appBrick_statusTag" {...contentObj}>
        {contentObj.text}
      </div>
    )
  );
};

export default StatusTag;
