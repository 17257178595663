import regeneratorRuntime from 'regenerator-runtime';
import 'scroll-behavior-polyfill';
// Shared Libs
import 'l10n/l10n';
import '../shared/js/async_storage';
// Libs
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Router from 'preact-router';
import { MessageListener, MessageSender } from 'web-message-helper';
import {
  RequestExitCommand,
  ShowToastCommand,
  SyncLoadingProgressCommand,
} from 'kaistore-post-messenger/src/commands';
import { Toast } from 'kaistore-post-messenger/src/models';
import cx from 'classnames';

import ReactSoftKey from 'react-soft-key';
import Account from '@/account';
import {
  APP_ORIGIN,
  APP_HTTP_ORIGIN,
  PATH,
  SPECIAL_CATE_CODE,
} from '@/constant';
import { getQuerystring, isNextOS } from '@/utils';
import AppStore from '@/app-store';
import { mozActivityManager } from '@/mozactivity-manager';
import { deviceUtils } from '@/device-utils';
import receiveMessage from '@/helper/message-receiver';
import analyticsHelper from '@/helper/analytics-helper';
import { loggerHelper } from '@/helper/logger-helper';
import paymentHelper from '@/helper/payment-helper';
import UserInterfaceHelper, {
  setThemeColor,
} from '@/helper/user-interface-helper';
import routeHelper from '@/helper/route-helper';
import searchHelper from '@/helper/search-helper';

import '@/lib/debug-error.js';

// Component
import ReactDialog from 'react-dialog';
// panels
import AppsPanel from '@/panel/AppsPanel';
import AsyncPanel from '@/component/AsyncPanel';

// CSS
import 'react-soft-key/assets/index.scss';
import 'scss/app.scss';
import 'scss/largetext.scss';

import 'scss/gaia-styles/gaia-theme.css';
import 'scss/gaia-styles/gaia-font.css';
import 'scss/gaia-styles/gaia-icons.css';

const KEY_SERVER_TIME_OFFSET = 'hawk-request-server-time-offset';

class App extends Component {
  constructor() {
    super();
    window.remoteStartTime = new Date().getTime();
    performance.mark('REMOTE_STORE');
    performance.mark('REMOTE_PAGE_RENDERING_START');
    MessageSender.remoteReady = true;
    MessageSender.postFullQueue();
    this.syncLoadingProgress(70);
    this.dialogQueue = [];
    // get default state.
    this.state = this.getInitialState();
    this.isTokenRefreshed = false;
    this.largeTextEnabled = false;
    this.hasGotToken = false;
    this.dialogRef = React.createRef();

    /**
     * Make KaiOS 3.0 focus behavior as same as KaiOS 2.5
     * Remote page will grab the focus directly on KaiOS 3.0 while doing
     * Element.focus() even if the focus is not on the iframe.
     **/
    if (isNextOS()) {
      this.overwrite();
    }
    // init KaiOS account
    Account.init();
    this.startMessengers();
  }

  overwrite() {
    const __focus__ = HTMLElement.prototype.focus;

    HTMLElement.prototype.focus = function() {
      // Record the latest element preparing to focus.
      UserInterfaceHelper.focusElement = this;
      // Add setTimeout to drop down the latency of postMessage.
      setTimeout(() => {
        if (UserInterfaceHelper.UIHasFocus) {
          __focus__.apply(this, arguments);
        }
      });
    };
  }

  getInitialState() {
    const initialState = {
      warningMessageL10nId: 'device-issue-detail',
      dialog: false,
      dialogOptions: {},
      plusInfo: {
        version: '2.4', // default
      },
      readyToRoute: false, // true when all prerequisites have finished
      lastAPIFetched: false, // true when apps/combo and /apps have fetched
      visibilityState: document.visibilityState,
    };
    return initialState;
  }

  resetState() {
    this.prepareDownload = AppStore.preparedDownloadApp;
    AppStore.resetStore();
    this.setState(this.getInitialState());
  }

  setServerTimeOffset() {
    // Get the time offset from indexedDB if it exists
    asyncStorage.getItem(KEY_SERVER_TIME_OFFSET, result => {
      window.serverTimeOffset = result;
    });
  }

  reloadRemoteServices = () => {
    this.resetState();
    this.fetchDataAndGenerateUI();
  };

  fetchDataAndGenerateUI() {
    // show loading panel.
    routeHelper.route(PATH.LOADING.URL());
    // show no network UI and early return here.
    if (!navigator.onLine) {
      // reset state to make sure UI works correctly.
      this.setMsg('no-internet');
      routeHelper.route(PATH.WARNING.URL());
      this.setState({ warningMessageL10nId: 'no-internet-with-tips' });
      setThemeColor();
      console.error('no network');
      return;
    }
    UserInterfaceHelper.UIReadyCommandSent = false;
    // Start to request things from server,
    // such as token, app-list, categories and service worker.
    this.startRemoteServices();
  }

  async startRemoteServices(refreshToken = false) {
    const querystring = getQuerystring(routeHelper.entryURL);
    const searchParams = new URLSearchParams(querystring);
    const disposition = searchParams.get('disposition');
    const manifestURL = searchParams.get('manifest');
    const name = searchParams.get('name');
    const version = searchParams.get('version');
    const largeText = searchParams.get('largeText');
    const debug = searchParams.get('debug');

    if (largeText === 'true') {
      this.largeTextEnabled = true;
    }
    if (debug === 'true') {
      UserInterfaceHelper.debug = true;
    }

    // These helpers only need to be initialized once
    // If refreshToken is true, it means startRemoteServices is called for retry
    if (!refreshToken) {
      // init after deviceUtils.information available
      analyticsHelper.init(version);
      loggerHelper.init();
      paymentHelper.init();
      searchHelper.init();
      AppStore.initHelpers();
    }

    // First, get token
    try {
      performance.mark('REQUEST_TOKEN_START');
      await Account.requestToken(refreshToken).then(({ hasToken }) => {
        performance.mark('REQUEST_TOKEN_END');
        this.isTokenRefreshed = refreshToken;
        this.hasGotToken = hasToken;
      });
    } catch (e) {
      console.error('No restricted token for fetch apis', e);
    }

    // Second, fetch purchased apps and apps for launching based on entryURL
    AppStore.initPurchasedApps();

    try {
      const initBookmarksPromise = AppStore.initBookmarksMap().then(
        ({ isBookmarkDBSupported }) => {
          deviceUtils.isBookmarkDBSupported = isBookmarkDBSupported;
        }
      );
      const fetchInitialAppsPromise = this.handleFetchInitialApps({
        disposition,
        manifestURL,
        name,
      });
      await Promise.all([fetchInitialAppsPromise, initBookmarksPromise]).then(
        ([[isInlineGraphQL]]) => {
          this.syncLoadingProgress(85);
          return this.prepareApps(isInlineGraphQL);
        }
      );
    } catch (e) {
      // only retry ONCE with a refreshed token if it was the token issue (401)
      if (e.status === 401 && !this.isTokenRefreshed) {
        this.startRemoteServices(true);
      } else if (e.errorMsgId) {
        this.serverError(e);
      } else {
        // TODO: some cases like inline activity without name and manifest can't be handled by serverError()
        console.error(e);
      }
      return;
    }

    this.syncLoadingProgress(99);
    setThemeColor();
    this.setState({ lastAPIFetched: true, readyToRoute: true });
  }

  // Return a Promise.all that with a signature of [isInlineGraphQLPromise, ...restPromises]
  // isInlineGraphQLPromise is for prepareApps to decide if some of additional setup should be done
  // since "BackKey" for inline activity usually means return to the caller app
  handleFetchInitialApps({ disposition, manifestURL, name }) {
    /**
     * 1. inline activity ("inline-open-page", "inline-open-by-name"):
     *    Only need to get the intended app (ideally through graphQL) since it will return to caller when back,
     *    no need to get combo / applist unless graphQL failed
     * 2. window activity ("open-page", "open-by-name", or "open-deeplink" w/ or w/o manifestURL):
     *    "open-deeplink" without manifestURL: Just launch store with combo api
     *    Others: Need to get the intended app (from graphQL, fallback to applist if failed), and combo (ideally from cache if available)
     * 3. Non-activity: get combo
     */

    const graphQLQuery = {};
    if (manifestURL) graphQLQuery.manifestURL = manifestURL;
    if (name) graphQLQuery.name = name;

    switch (disposition) {
      case 'inline': {
        // "inline-open-page" or "inline-open-by-name"
        if (!name && !manifestURL) {
          return Promise.reject(new Error('no name and no manifestURL'));
        }
        return this.prepareInlineActivityApp(graphQLQuery);
      }
      case 'window': {
        if (name || manifestURL) {
          // launch store via "open-page", "open-by-name", or "open-deeplink" with apps={manifestURL} specified
          return this.prepareWindowActivityApp(graphQLQuery);
        }
        // launch store via "open-deeplink" without specifying manifestURL
        return this.fetchComboAPI();
      }
      default:
        // launch store normally
        return this.fetchComboAPI();
    }
  }

  prepareWindowActivityApp(graphQLQuery) {
    const graphQLPromise = AppStore.fetchGraphQLForActivity(graphQLQuery);
    const comboPromise = AppStore.fetchComboList(this.hasGotToken);
    const isInlineGraphQLPromise = Promise.resolve(false);
    return Promise.all([
      isInlineGraphQLPromise,
      graphQLPromise,
      comboPromise,
    ]).catch(e => {
      console.error('Either graphQL api or combo api failed:', e);
      // If graphQL endpoint failed, fall back to get all apps
      const appListPromise = AppStore.fetchAppListByCate(SPECIAL_CATE_CODE.ALL);
      return Promise.all([
        isInlineGraphQLPromise,
        comboPromise,
        appListPromise,
      ]);
    });
  }

  prepareInlineActivityApp(graphQLQuery) {
    const graphQLPromise = AppStore.fetchGraphQLForActivity(graphQLQuery);
    const isInlineGraphQLPromise = Promise.resolve(true);
    return Promise.all([isInlineGraphQLPromise, graphQLPromise]).catch(e => {
      console.error(e);
      /*
       * Corner Case Handling
       * Go to app list when the app is not found. In this case, the loading
       * time of first view will be longer. User will stuck at 85% for a
       * while since we are fetching all apps and categories.
       */
      return this.fetchAppsAndCategories();
    });
  }

  fetchComboAPI() {
    performance.mark('FETCH_APPS_CAT_START');
    const isInlineGraphQLPromise = Promise.resolve(false);
    const fetchComboPromise = AppStore.fetchComboList(this.hasGotToken);
    return Promise.all([isInlineGraphQLPromise, fetchComboPromise]);
  }

  fetchAppsAndCategories() {
    // fetch latest app list
    performance.mark('FETCH_APPS_CAT_START');
    const isInlineGraphQLPromise = Promise.resolve(false);
    const comboPromise = AppStore.fetchComboList(this.hasGotToken);
    const appListPromise = AppStore.fetchAppListByCate(SPECIAL_CATE_CODE.ALL);
    return Promise.all([isInlineGraphQLPromise, comboPromise, appListPromise]);
  }

  startMessengers() {
    MessageSender.validMessageOrigins = [APP_ORIGIN, APP_HTTP_ORIGIN];
    MessageSender.targetOrigin = isNextOS() ? APP_HTTP_ORIGIN : APP_ORIGIN;
    MessageSender.start();
    MessageListener.start(receiveMessage);
  }

  startEventListener() {
    window.addEventListener('keydown', this.keydownHandler);
    window.addEventListener('online', this.reloadRemoteServices);
    window.addEventListener('offline', this.reloadRemoteServices);
    window.addEventListener('account:login', this.reloadRemoteServices);
    window.addEventListener('account:logout', this.reloadRemoteServices);

    window.addEventListener(
      'hawkrequester:offsetchange',
      this.handleHawkOffsetChange
    );
    window.addEventListener('visibilitychange', this.handleVisibilityChange);
  }

  keydownHandler = event => {
    const { key } = event;

    switch (key) {
      case 'BrowserBack':
      case 'Backspace':
      case 'Escape':
        // Caller app launch store via deeplink.
        if (mozActivityManager.shouldPostResultToCaller) {
          event.preventDefault();
          mozActivityManager.shouldPostResultToCaller = false;
        } else if (AppStore.storeUpdatable) {
          event.preventDefault();
        }
        MessageSender.send(new RequestExitCommand());
        break;
      default:
        break;
    }
  };

  syncLoadingProgress(percentage) {
    const command = new SyncLoadingProgressCommand({
      detail: { percentage },
    });
    MessageSender.send(command);
  }

  showRestartDialog = () => {
    const restartDialogOptions = {
      header: 'no-price-header',
      type: 'alert',
      ok: 'ok',
      content: 'restart-phone-msg',
      onOk: this.hideDialog,
    };
    this.showDialog(restartDialogOptions);
  };

  setMsg(messageL10nId, messageL10nArgs = {}) {
    if (document.visibilityState === 'hidden') {
      // Avoid to show toast when app is in background.
      return;
    }
    const command = new ShowToastCommand({
      detail: new Toast({
        messageL10nId,
        messageL10nArgs,
      }),
    });
    MessageSender.send(command);
  }

  redirectAppPage(manifestURL) {
    let activeApp = null;

    if (manifestURL) {
      activeApp = AppStore.findAppByManifest(manifestURL);
      if (activeApp) {
        routeHelper.route(
          PATH.PAGE.URL({ id: activeApp.id, autoDownload: true })
        );
      } else {
        this.handleAppNotFound();
      }
    }
  }

  // TODO: does isInlineGraphQL matter? It seems like the only diff is if we need to add event listener
  prepareApps(isInlineGraphQL) {
    const syncInstalledAppsPromise = AppStore.syncInstalledApps();

    if (isInlineGraphQL) {
      // For case that the store is launched by inline activity through graphQL
      // Do minimum things to show the app detail page
      return syncInstalledAppsPromise;
    }

    return syncInstalledAppsPromise.then(() => {
      /**
       * Handle the task immediately which has existed before the event listener
       * is ready.
       */
      const { task } = mozActivityManager;

      if (task) {
        mozActivityManager.handleTask(task, this.handleAppNotFound);
      }
      /**
       * Add event listener to handle Push Notification after sync installed apps and bookmark
       * to make sure the app install status is correct
       * and just in case they push bookmark in the future
       */
      window.addEventListener('task-queue-updated', () => {
        const mozActivityTask = mozActivityManager.task;
        if (mozActivityTask) {
          mozActivityManager.handleTask(
            mozActivityTask,
            this.handleAppNotFound
          );
        }
      });
    });
  }

  handleHawkOffsetChange = event => {
    const offset = event.detail.current;
    window.serverTimeOffset = offset;
    asyncStorage.setItem(KEY_SERVER_TIME_OFFSET, offset);
  };

  handleVisibilityChange = () => {
    console.error(`[store focus debug VC]: ${document.visibilityState}`);

    this.setState({
      visibilityState: document.visibilityState,
    });
  };

  handleAppNotFound = () => {
    // Launch app list first and then launch alert to notice user
    // can't find app.
    this.showNotFoundDialog();
  };

  showNotFoundDialog = () => {
    const notFoundDialogOptions = {
      header: 'not-found-header',
      type: 'alert',
      ok: 'ok',
      content: 'not-found-msg',
      onOk: this.hideDialog,
    };
    this.showDialog(notFoundDialogOptions);
  };

  serverError(error) {
    const { errorMsgId } = error;
    if (errorMsgId) {
      if (errorMsgId === 'no-app-available') {
        this.setState({
          warningMessageL10nId: 'no-available-app',
        });
        routeHelper.route(PATH.WARNING.URL());
        UserInterfaceHelper.notifyUIReady();
      } else {
        this.setMsg(errorMsgId);
      }
    }
  }

  stopEventListener() {
    // TODO
  }

  componentDidMount() {
    performance.mark('REQUEST_DEVICE_START');
    deviceUtils.requestInfo().then(() => {
      performance.mark('REQUEST_DEVICE_END');
      this.startEventListener();
      this.setServerTimeOffset();
      this.fetchDataAndGenerateUI();
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { entryURL } = routeHelper;
    const querystring = getQuerystring(entryURL);
    const searchParams = new URLSearchParams(querystring);
    const needPostResult = searchParams.get('postResult') === 'true';

    if (prevState.dialog !== this.state.dialog) {
      if (this.state.dialog) {
        this.onDialogInShowState();
      } else {
        this.onDialogInHideState();
      }
    }

    const appDetailAsEntrance = entryURL.startsWith('/app');
    if (prevState.readyToRoute === false && this.state.readyToRoute === true) {
      if (!this.prepareDownload && !appDetailAsEntrance) {
        mozActivityManager.shouldPostResultToCaller = needPostResult;
        routeHelper.route(entryURL);
        if (AppStore.kaipayUpdated) {
          this.showRestartDialog();
        }
      }
    }
    if (
      prevState.lastAPIFetched === false &&
      this.state.lastAPIFetched === true
    ) {
      if (this.prepareDownload) {
        this.redirectAppPage(this.prepareDownload);
      } else if (appDetailAsEntrance) {
        mozActivityManager.shouldPostResultToCaller = needPostResult;
        routeHelper.route(routeHelper.entryURL);
      }
    }
  }

  showDialog(options) {
    if (this.state.dialog === true || routeHelper.isOnLoadingPage) {
      this.dialogQueue.push(options);
    } else {
      this.setState({
        dialog: true,
        dialogOptions: options,
      });
      UserInterfaceHelper.UIState.dialog = true;
    }
  }

  hideDialog = callback => {
    UserInterfaceHelper.UIState.dialog = false;
    this.setState(
      {
        dialog: false,
        dialogOptions: {},
      },
      () => {
        if (callback) {
          callback();
        }
      }
    );
  };

  showNextDialog() {
    const nextDialog = this.dialogQueue.shift();
    if (nextDialog) {
      this.showDialog(nextDialog);
      return true;
    }
    return false;
  }

  ensureDialogDisplay = () => {
    if (this.state.dialog) {
      this.onDialogInShowState();
    } else {
      this.showNextDialog();
    }
  };

  onDialogInShowState() {
    const lastActive = document.activeElement;
    const isLostFocus = () => lastActive.tagName === 'BODY';

    this.dialogRef.current.show();
    this.dialogRef.current.on('closed', () => {
      if (this.state.dialog) {
        // close by endKey
        this.hideDialog();
      }
      if (this.showNextDialog() === false) {
        if (isLostFocus()) {
          window.dispatchEvent(new CustomEvent('refocus'));
        } else {
          lastActive.focus();
        }
      }
    });
  }

  onDialogInHideState() {
    this.dialogRef.current.hide();
  }

  onRouteChange = event => {
    if (routeHelper.entryURL === null) {
      routeHelper.entryURL = event.url;
    }
  };

  render() {
    const { dialog, visibilityState } = this.state;
    const containerClasses = cx('App', {
      'large-text': this.largeTextEnabled,
    });
    const mainDialogClasses = cx({
      hidden: !dialog,
    });

    return (
      <div className={containerClasses} id="app" tabIndex="-1">
        <Router onChange={this.onRouteChange}>
          <AsyncPanel panelName="LoadingPanel" path={PATH.LOADING.MATCH} />
          <AsyncPanel
            panelName="WarningMessagePanel"
            path={PATH.WARNING.MATCH}
            warningMessageL10nId={this.state.warningMessageL10nId}
          />
          {this.state.readyToRoute && (
            <AppsPanel
              path={PATH.APPS.MATCH}
              ensureDialogDisplay={this.ensureDialogDisplay}
              visibilityState={visibilityState}
              default
            />
          )}
          <AsyncPanel panelName="SettingsPanel" path={PATH.SETTING.MATCH} />
          <AsyncPanel panelName="SearchPanel" path={PATH.SEARCH.MATCH} />
          <AsyncPanel
            panelName="DownloadHistoryPanel"
            path={PATH.DOWNLOAD_HISTORY.MATCH}
            ensureDialogDisplay={this.ensureDialogDisplay}
          />
          {this.state.readyToRoute && (
            <AsyncPanel
              panelName="PagePanel"
              path={PATH.CORE_PAGE.MATCH}
              onAppNotFound={this.showNotFoundDialog}
            />
          )}
          {this.state.readyToRoute && (
            <AsyncPanel
              panelName="AppDetailPanel"
              path={PATH.PAGE.MATCH}
              onAppNotFound={this.showNotFoundDialog}
            />
          )}
        </Router>
        <ReactSoftKey />
        <div id="main-dialog" className={mainDialogClasses}>
          <ReactDialog ref={this.dialogRef} {...this.state.dialogOptions} />
        </div>
      </div>
    );
  }
}

navigator.mozL10n.once(() => {
  ReactDOM.render(<App />, document.getElementById('root'));
});
