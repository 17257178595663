import 'scss/component/UpdateBrick.scss';

import React, { Component } from 'react';
import ProgressBar from 'progress-bar';
import cx from 'classnames';

import { isOverflown } from '@/helper/user-interface-helper';
import Marquee from '../Marquee';

class UpdateBrick extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFocusing: false,
      isTitleOverflown: false,
      isImageLoaded: false,
    };
  }

  onKeyDown = evt => {
    const { onPress } = this.props;
    if (evt.key === 'Enter' && onPress) {
      onPress(evt);
    }
  };

  onFocus = () => {
    const state = {
      isFocusing: true,
    };
    if (isOverflown(this.name)) {
      state.isTitleOverflown = true;
    }
    this.setState(state);
    this.props.onBrickFocus && this.props.onBrickFocus();
  };

  onBlur = () => {
    this.setState({ isFocusing: false });
  };

  handleImageLoaded = () => {
    this.setState({ isImageLoaded: true });
  };

  render() {
    const {
      localized,
      size,
      icon,
      isDownloading,
      isPending,
      downloadProgress,
    } = this.props;

    const { isFocusing, isTitleOverflown, isImageLoaded } = this.state;

    return (
      <div
        className="update-brick focusable"
        tabIndex="1"
        onKeyDown={this.onKeyDown}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
      >
        <AppIcon
          icon={icon}
          handleLoad={this.handleImageLoaded}
          isImageLoaded={isImageLoaded}
        />
        <div className="content">
          <div
            className="name"
            ref={element => {
              this.name = element;
            }}
          >
            {isFocusing && isTitleOverflown ? (
              <Marquee>
                <div>{localized.name}</div>
              </Marquee>
            ) : (
              <div>{localized.name}</div>
            )}
          </div>
          {isDownloading ? (
            <div className="progress-bar-wrapper">
              <ProgressBar
                height={0.6}
                hasTip={true}
                infinite={!(downloadProgress > 0)}
                percentage={downloadProgress}
              />
            </div>
          ) : (
            <div className="size">{size}</div>
          )}
        </div>
        <div className="indicator">
          {isPending ? <div className="pending-icon" /> : null}
        </div>
      </div>
    );
  }
}

const AppIcon = ({ icon, isImageLoaded, handleLoad }) => {
  const iconClasses = cx('icon-wrapper', {
    loaded: isImageLoaded,
  });

  return (
    <div className={iconClasses}>
      <img className="app-icon" src={icon} alt="" onLoad={handleLoad} />
    </div>
  );
};

export default UpdateBrick;
