import React, { Component } from 'react';

import Account from '@/account';
import { deviceUtils } from '@/device-utils';
import { registerSoftKey } from '@/utils';
import { PATH } from '@/constant';
import OptionMenu from '@/component/OptionMenu';
import { AVAILABLE_OPTION_MENU_ITEM } from '@/component/OptionMenu/constants';

import UserInterfaceHelper from '@/helper/user-interface-helper';
import CacheHelper from '@/helper/cache-helper';
import routeHelper from '@/helper/route-helper';

import { AppsView } from './component';

import 'scss/AppsPanel.scss';

class AppsPanel extends Component {
  constructor(props) {
    super(props);
    this.appsPanelRef = React.createRef();
    this.appsViewRef = React.createRef();
    this.state = {
      showOptionMenu: false,
    };
  }

  componentDidMount() {
    window.addEventListener('refocus', this.focusOnAppsView);
    UserInterfaceHelper.notifyUIReady().then(result => {
      const { hasSentBefore } = result;
      this.init();
      if (!hasSentBefore) {
        // only update cached item when UI is ready for the first time
        CacheHelper.updateCachedItem();
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('refocus', this.focusOnAppsView);
  }

  focusOnAppsView = () => {
    console.error('[store focus debug focusOnAppsView]');
    this.appsViewRef.current.handleFocus();
    const option = {
      left: {
        icon: 'search',
      },
      center: {
        text: 'select',
      },
      right: {
        icon: 'more',
      },
    };
    registerSoftKey(option, this.appsPanelRef.current);
  };

  toggleOptionMenu = () => {
    const { showOptionMenu } = this.state;
    UserInterfaceHelper.UIState.optionMenu = !showOptionMenu;
    if (showOptionMenu) {
      this.focusOnAppsView();
    }
    this.setState({ showOptionMenu: !showOptionMenu });
  };

  init() {
    this.focusOnAppsView();
    this.props.ensureDialogDisplay();
  }

  handleKeydown = e => {
    const { showOptionMenu } = this.state;
    switch (e.key) {
      case 'Enter': {
        const { target } = e;
        this.handleEnter(target);
        break;
      }
      case 'SoftRight': {
        if (!showOptionMenu) this.toggleOptionMenu();
        break;
      }
      case 'SoftLeft': {
        if (!showOptionMenu) routeHelper.route(PATH.SEARCH.URL());
        break;
      }
      default:
        break;
    }
  };

  handleEnter(target) {
    const { id, appOrder } = target.dataset;
    if (!id) {
      return;
    }
    routeHelper.route(PATH.PAGE.URL({ id, appOrder: parseInt(appOrder, 10) }));
  }

  getOptions = () => {
    const {
      PRIVACY_POLICY,
      TERMS_AND_SERVICES,
      NEED_HELP,
      SETTINGS_AND_ACCOUNT,
      SETTINGS,
      DOWNLOAD_HISTORY,
    } = AVAILABLE_OPTION_MENU_ITEM;
    const defaultOptions =
      Account.version !== null
        ? [DOWNLOAD_HISTORY, PRIVACY_POLICY, TERMS_AND_SERVICES, NEED_HELP]
        : [PRIVACY_POLICY, TERMS_AND_SERVICES, NEED_HELP];

    if (deviceUtils.isSettingPanelSupported) {
      return Account.version
        ? [SETTINGS_AND_ACCOUNT, ...defaultOptions]
        : [SETTINGS, ...defaultOptions];
    }

    return defaultOptions;
  };

  render() {
    // TODO: flatten the component tree for easier focus management by moving child components in AppsView to here.
    // We need <CategoryTab />, <(update)AppListView />, and <OptionMenu />
    // need to review focus handling when this is done
    const { showOptionMenu } = this.state;
    const { categoryCode } = this.props.matches;
    const { visibilityState } = this.props;
    return (
      <div
        className="Appspanel"
        tabIndex="1"
        ref={this.appsPanelRef}
        onKeyDown={this.handleKeydown}
      >
        <AppsView
          ref={this.appsViewRef}
          categoryCode={categoryCode}
          visibilityState={visibilityState}
        />
        <OptionMenu
          options={this.getOptions()}
          toggleOptionMenu={this.toggleOptionMenu}
          showOptionMenu={showOptionMenu}
        />
      </div>
    );
  }
}

export default AppsPanel;
